import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import SecondaryButton from "../../../../common/atoms/secondarybutton";
import { DataBox, Input, UploadPicture } from "..";
import { handleDownload } from "../../../apliecinajumi/helpers";
import { Trans } from "react-i18next";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import Tooltip from "../../../../common/atoms/tooltip";
import { formatLatvianDate } from "../../../card/components/dataholder";
import Loader from "../../../../common/loader";
import { generateRandomKey } from "../../../../helpers/generateRandomKey";
import { StateContext } from "../../../../context";

const getAllowedFormats = (selectedSyntax) => {

  if (!selectedSyntax) return [];

  return selectedSyntax.includes("other")
    ? ["ZIP", "XML", "JSON", "CSV", "GEOJSON", "XLSX"]
    : selectedSyntax.map((syntax) => syntax.toUpperCase());
};

const getAllowedFormatsSmall = (selectedSyntax) => {

  if (!selectedSyntax) return [];
  
  return selectedSyntax.includes("other")
    ? ".zip, .xml, .json, .csv, .geojson, .xlsx"
    : selectedSyntax.map((syntax) => `.${syntax}`).join(", ");
};

const removeDotFromFormat = (format) => format.replace(".", "");

const validateFileForErrors = (allowedFormats, file, sample = false) => {
  let errors = [];

  if (!file) return null;

  if (!allowedFormats.length) {
    return null;
  }

  if (file.error) {
    errors.push(file.error);
  }

  if (sample) {
    if (file && file?.name && !allowedFormats.includes(file.name.split(".").pop().toUpperCase())) {
      errors.push("datasetErrorCheckFile");
    }
  } else {
    if (!file.text) errors.push("datasetErrorNoFileDescriptionLatvian");
    if (!file.text_en) errors.push("datasetErrorNoFileDescriptionEnglish");
    if (!file.file && !file.path) errors.push("datasetErrorNoFile");
    if (file.name && !allowedFormats.includes(file.name.split(".").pop().toUpperCase()))
      errors.push("datasetErrorCheckFile");
    else if (file.path && !allowedFormats.includes(file.path.split(".").pop().toUpperCase()))
      errors.push("datasetErrorCheckFile");
  }

  return errors.length ? errors : null;
};

const FileBox = ({
  loading,
  selectedSyntax,
  getValue,
  files,
  setFiles,
  fileErrors,
  setFileErrors,
  setDatasetDescriptionFilesChanged,
}) => {
  const { register, unregister, setValue } = useForm();

  const allowedFormats = useMemo(() => getAllowedFormats(selectedSyntax), [selectedSyntax]);
  // const [allowedFormats, setAllowedFormats] = useState(getAllowedFormats(selectedSyntax));

  const allowedFormatsString = useMemo(() => {
    if (!allowedFormats.length) return "";
    return "(" + allowedFormats.map((format) => `${removeDotFromFormat(format)}`).join(", ") + ")";
  }, [allowedFormats]);

  const allowedFormatsSmall = useMemo(() => getAllowedFormatsSmall(selectedSyntax), [selectedSyntax]);

  const updateFileErrors = useCallback(
    (errors, index) => {
      const errorsToKeep = ["datasetErrorFailedValidation"];

      setFileErrors((prevErrors) => {
        let newFileErrors = { ...prevErrors };

        if (errors) {
          newFileErrors[index] = errors;
        } else {
          newFileErrors[index] = (newFileErrors[index] || []).filter(error => errorsToKeep.includes(error));
          if (newFileErrors[index].length === 0) {
            delete newFileErrors[index];
          }
        }
        return newFileErrors;
      });
    },
    [setFileErrors]
  );

  useEffect(() => {

    console.log("files", files);

    files.forEach((file, index) => {
      if (file.file || file.path || file.error) {

        const errors = validateFileForErrors(allowedFormats, file);

        updateFileErrors(errors, index);
      } else {
        // this handles a new file addition when no it has no file
        updateFileErrors(["datasetErrorNoFile"], index);
      } 

    });
  // disabling so there isnt an infinite loop
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSyntax, files, updateFileErrors]);

  const addFile = useCallback(() => {
    setFiles((prevFiles) => {
      const file = {
        file: null,
        name: null,
        text: null,
        text_en: null,
        index: prevFiles.length,
        key: generateRandomKey(),
      };

      return [...prevFiles, file];
    });
  }, [setFiles]);

  const handleRestoreFile = useCallback(
    (index) => {
      return () => {
        setFiles((prevFiles) => {
          const updatedFiles = [...prevFiles];
          updatedFiles[index] = {
            ...updatedFiles[index],
            originalData: {
              ...updatedFiles[index],
            },
            text: prevFiles[index].text || prevFiles[index].text,
            text_en: prevFiles[index].text_en || prevFiles[index].text_en,
            name: prevFiles[index].path.split("/").pop(),
            can_restore: false,
          };

            setValue(`file${updatedFiles[index].key}`, updatedFiles[index].text);
            setValue(`fileEn${updatedFiles[index].key}`, updatedFiles[index].text_en);

          return updatedFiles;
        });
        const newErrors = validateFileForErrors(allowedFormats, files[index]);
        if (files[index]) {
          updateFileErrors(newErrors ? newErrors : null, index);
        }
      };
    },
    [allowedFormats, files, setFiles, setValue]
  );

  const handleCancelRestoreFile = useCallback(
    (index) => {
      setFiles((prevFiles) => {
        const updatedFiles = [...prevFiles];
        updatedFiles[index] = {
          ...updatedFiles[index]?.originalData,
          can_restore: true,
        };

        unregister(`file${updatedFiles[index].key}`);
        unregister(`fileEn${updatedFiles[index].key}`);

        return updatedFiles;
      });
    },
    [setFiles, unregister]
  );
  const handleRemoveFile = useCallback(
    (indexToRemove) => {
      const result = window.confirm(t("popupMessageConfirmDeleteFile"));

      if (!result) return;

      unregister(`fileEn${files[indexToRemove].key}`);
      unregister(`file${files[indexToRemove].key}`);
      setFiles((prevFiles) => {
        const filteredFiles = prevFiles.filter((file) => file.index !== indexToRemove);
        const updatedFiles = filteredFiles.map((file, index) => ({
          ...file,
          index,
        }));
        return updatedFiles;
      });
      setFileErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        for (let i = indexToRemove; i < files.length - 1; i++) {
          newErrors[i] = newErrors[i + 1];
        }
        delete newErrors[files.length - 1];
        return newErrors;
      });
    },
    [unregister, files, setFiles, setFileErrors]
  );

  const handleFileUpload = useCallback(
    (index, file) => {
      setFiles((prevFiles) => {
        const updatedFiles = [...prevFiles];
        delete updatedFiles[index].file;
        delete updatedFiles[index].name;
        return updatedFiles;
      });

      if (file.size > 60 * 1024 * 1024) {
        window.alert(t("datasetFileSizeError"));
        return;
      }

      setFiles((prevFiles) => {
        const updatedFiles = [...prevFiles];
        updatedFiles[index].file = file;
        updatedFiles[index].name = file.name;

        updatedFiles[index].error = null;


        const errors = validateFileForErrors(allowedFormats, updatedFiles[index]);
        // here forcefully update errors, to reset xml validation on file upload
        setFileErrors((prevErrors) => {
          let newFileErrors = { ...prevErrors };

          if (errors) {
            newFileErrors[index] = errors;
          } else {
            delete newFileErrors[index];
          }
          return newFileErrors;
        }
      );

        return updatedFiles;
      });
    },
    [setFiles, allowedFormats, setFileErrors, updateFileErrors]
  );

  const handleFileDescription = useCallback(
    (index, name) => {
      setFiles((prevFiles) => {
        const updatedFiles = [...prevFiles];


        updatedFiles[index].text = name;



        const errors = validateFileForErrors(allowedFormats, updatedFiles[index])


        updateFileErrors(errors, index);

        return updatedFiles;
      });
    },
    [setFiles, updateFileErrors, allowedFormats]
  );

  const handleFileDescriptionEnglish = useCallback(
    (index, name) => {
      setFiles((prevFiles) => {
        const updatedFiles = [...prevFiles];
        updatedFiles[index] = {
          ...updatedFiles[index],
          text_en: name,
        };
        updateFileErrors(validateFileForErrors(allowedFormats, updatedFiles[index]), index);
        return updatedFiles;
      });
    },
    [setFiles, updateFileErrors, allowedFormats]
  );

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "flex-start", marginLeft: 10 }}>
        <Loader />
      </div>
    );
  }

  return (
    <>
      {files?.map((file) => (
        <DataBox
          key={file.key}
          style={{
            marginTop: 50,
          }}
          children={[
            <>
              <div key={file.index} style={{ marginTop: 10 }}>
                <div className="overall-data-box-header">

                    <span>{`${t("datasetAddDataFile")} ${file.index + 1}`}</span>

                  <div className="overall-data-box-header-date">
                    <span>{t("addedAt")}</span>
                    <span>
                      {file.target_id ? formatLatvianDate(file.datetime, true) : formatLatvianDate(new Date())}
                    </span>
                  </div>
                </div>

                {!file?.can_restore && (
                  <div
                    className="input-group"
                    style={{
                      marginTop: 20,
                      marginBottom: 30,
                    }}
                  >

                        <Input
                          name={`file${file.key}`}
                          register={register}
                          getValues={() => file.text ?? ""}
                          getFieldState={() => file.text ?? ""}
                          placeholder={t("fileDescriptionLatvian")}
                          value={file.text}
                          onChange={(e) => handleFileDescription(file.index, e.target.value)}
                          required
                          maxLength={250}
                          errors={
                            fileErrors[file.index]?.find((error) =>
                              error.includes("datasetErrorNoFileDescriptionLatvian")
                            )
                              ? { [`file${file.key}`]: { message: "datasetErrorNoFileDescriptionLatvian" } }
                              : {}
                          }
                        />
                        <Input
                          name={`fileEn${file.key}`}
                          errors={
                            fileErrors[file.index]?.find((error) =>
                              error.includes("datasetErrorNoFileDescriptionEnglish")
                            )
                              ? { [`fileEn${file.key}`]: { message: "datasetErrorNoFileDescriptionEnglish" } }
                              : {}
                          }
                          customInputStyles={{ marginTop: 0 }}
                          register={register}
                          getValues={() => file.text_en ?? ""}
                          getFieldState={() => file.text_en ?? ""}
                          placeholder={t("fileDescriptionEnglish")}
                          value={file.text_en}
                          onChange={(e) => {
                            handleFileDescriptionEnglish(file.index, e.target.value);
                          }}
                          required
                          maxLength={250}
                        />
                  </div>
                )}

                <div
                  style={{
                    marginTop: 10,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {/* FILE NAME */}
                  {file?.can_restore ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "1em",
                      }}
                    >
                      <div className="overall-file-uploaded-text-container">
                        <span
                          className="overall-file-upload-text overall-file-upload-text-filename focus-red"
                          style={{
                            margin: 0,
                            cursor: file?.path ? "pointer" : "default",
                            textDecoration: file?.path ? "underline" : "none",
                          }}
                          onClick={() => {
                            if (file.path) {
                              handleDownload(file.path, file.path.split("/").pop());
                            }
                          }}
                        >
                          {decodeURIComponent(file.path.split("/").pop())}
                        </span>
                        <span className="overall-file-upload-text overall-file-upload-text-filedescription">
                          {t("fileDescriptionLatvian")}:
                          <span className="overall-file-upload-text-filedescription-description">
                            {file.text}
                          </span>
                        </span>
                        <span className="overall-file-upload-text overall-file-upload-text-filedescription">
                          {t("fileDescriptionEnglish")}:
                          <span className="overall-file-upload-text-filedescription-description">
                            {file.text_en}
                          </span>
                        </span>
                      </div>
                      {fileErrors[file.index] && fileErrors[file.index].length && (
                        <Tooltip text={`${fileErrors[file.index]?.map((e) => t(e)).join("\n")}`}>
                          <img
                            alt="alt"
                            src="/assets/img/question.svg"
                            className={`tooltip-img red-filter`}
                          />
                        </Tooltip>
                      )}
                    </div>
                  ) : (
                    <>
                      {/* UPLOAD FILE */}
                      <input
                        type="file"
                        placeholder="Upload file"
                        id={`file${file.index}`}
                        style={{ display: "none" }}
                        onChange={(e) => handleFileUpload(file.index, e.target.files[0])}
                        accept={allowedFormatsSmall}
                      />
                      <div className="overall-upload-container" fieldName={allowedFormats}>
                        <UploadPicture
                          showButton={!file.file_id}
                          file={file.file}
                          title={t("datasetUploadDataFile")}
                          customText={
                            file.name ? decodeURIComponent(file.name) : "popupMessageFileNotAddedPlain"
                          }
                          customTextClassName={file.name ? "overall-file-upload-text-filename focus-red" : ""}
                          formats={allowedFormats}
                          onClick={(event) => {
                            event.preventDefault();
                            document.getElementById(`file${file.index}`).click();
                          }}
                        />
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              width: 15,
                              height: 15,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {fileErrors[file.index]?.find(
                              (error) =>
                                error.includes("datasetErrorCheckFile") ||
                                error.includes("datasetErrorFailedValidation")
                            ) ||
                            (!file.file && !file.target_id) ||
                            fileErrors[file.index]?.find((error) => error.includes("datasetErrorNoFile")) ? (
                              <Tooltip
                                text={
                                  !file.file && !file.target_id
                                    ? t("datasetErrorNoFile")
                                    : fileErrors[file.index]?.map((e) => t(e)).join(",\n")
                                }
                              >
                                <img
                                  alt="alt"
                                  src="/assets/img/question.svg"
                                  className={`${file.file && "red-filter"} tooltip-img`}
                                />
                              </Tooltip>
                            ) : (
                              <img alt="alt" src="/assets/img/check.svg" className="checkmark" />
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {/* DELETE FILE */}
                  {(file.target_id) && (
                    <div className="manaskopas-button-container">
                      <div
                        className="delete-button-container focus-red"
                        tabIndex={0}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") handleRemoveFile(file.index);
                        }}
                      >
                        <img
                          alt="alt"
                          className="manaskopas-main-tb1-tr2-td3-right-part2-sub2"
                          src="/assets/img/bin.svg"
                        />
                        <span
                          className="manaskopas-main-tb1-tr2-td3-right-part2-font2"
                          style={{ textDecoration: "underline" }}
                          onClick={() => handleRemoveFile(file.index)}
                        >
                          <Trans i18nKey="deleteFile" />
                        </span>
                      </div>
                      {file.can_restore && (
                        <div className="restore-button-container">
                          <SecondaryButton
                            disabled
                            style={{ justifyContent: "center", gap: 0 }}
                            invert
                            title={t("restoreFile")}
                            svg={"none"}
                            width={230}
                            onClick={() => handleRestoreFile(file.index)()}
                          />
                        </div>
                      )}
                      {!file.can_restore && file.target_id && (
                        <div className="cancel-restore-button-container">
                          <SecondaryButton
                            disabled
                            style={{ justifyContent: "center", gap: 0 }}
                            invert
                            title={t("cancelRestoreFile")}
                            svg={"none"}
                            width={230}
                            onClick={() => handleCancelRestoreFile(file.index)}
                          />
                        </div>
                      )}
                      {/* ADD FILE */}
                      {file.index === files.length - 1 && (
                        <SecondaryButton
                          disabled
                          style={{ justifyContent: "center", gap: 0 }}
                          invert
                          title={t("datasetAddFiles")}
                          svg={"add"}
                          width={230}
                          onClick={addFile}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </>,
          ]}
        />
      ))}
      {!files.length && (
        <div className="input-component" style={{ marginBottom: "2em" }}>
          <div className="register-info-right-profile-info-input-title">
            <span>{t("datasetUploadDataFiles")}</span>
          </div>
          <div>
            <div className="overall-upload-container" fieldName={allowedFormats}>
              <UploadPicture
                file="file"
                title={t("datasetAddDataFiles")}
                onClick={addFile}
                customSvgForButton="add"
                texts={[t("datasetFileSize"), allowedFormatsString]}
              />
              <div
                style={{
                  display: "flex",
                }}
              >
                <div
                  style={{
                    width: 15,
                    height: 15,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip text={`${t("datasetFileSize")} ${allowedFormatsString}`}>
                    <img alt="alt" src="/assets/img/question.svg" className={"tooltip-img"} />
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const SampleFileBox = ({
  sampleFile,
  setSampleFile,
  setSampleFileChanged,
  selectedSyntax,
  fileErrors,
  setFileErrors,
  customId = "sampleFile",
  customInputTitle = "dataSampleFull",
}) => {
  const { language } = useContext(StateContext);

  // for sample its 60mb for descriptions its 8mb
  const maxFileSize = customId === "sampleFile" ? 60 * 1024 * 1024 : 8 * 1024 * 1024;

  // const [allowedFormats, setAllowedFormats] = useState(getAllowedFormats(selectedSyntax));
  const allowedFormats = useMemo(() => getAllowedFormats(selectedSyntax), [selectedSyntax]);
  const allowedFormatsSmall = useMemo(() => getAllowedFormatsSmall(selectedSyntax), [selectedSyntax]);

  const allowedFormatsString = useMemo(() => {
    if (!allowedFormats.length) return "";
    return "(" + allowedFormats.map((format) => `${removeDotFromFormat(format)}`).join(", ") + ")";
  }, [allowedFormats]);

  /* eslint-disable react-hooks/exhaustive-deps */
  const inputTitle = useMemo(() => {
    return t(customInputTitle) + ":"
  }, [language, customInputTitle]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const updateFileErrors = useCallback(
    (errors, index) => {
      setFileErrors((prevErrors) => {
        let newFileErrors = { ...prevErrors };

        if (errors) {
          newFileErrors[index] = errors;
        } else {
          delete newFileErrors[index];
        }
        return newFileErrors;
      });
    },
    [setFileErrors]
  );

  const handleSampleFileUpload = useCallback(
    (file) => {
      if (file.size > maxFileSize) {
        window.alert(t("datasetFileSizeErrorUniversal") + " (" + maxFileSize / 1024 / 1024 + " MB)");
        return;
      }
      setSampleFile(file);
      setSampleFileChanged(true);
      const errors = validateFileForErrors(allowedFormats, file, true);
      updateFileErrors(errors, customId);
      return;
    },
    [setSampleFile, setSampleFileChanged, allowedFormats, updateFileErrors, customId]
  );

  const handleRemoveSampleFile = useCallback(
    (indexToRemove) => {
      const result = window.confirm(t("popupMessageConfirmDeleteFile"));
      if (result) {
        setSampleFile(null);
        setSampleFileChanged(true);
        return;
      }
    },
    [setSampleFile, setSampleFileChanged]
  );

  useEffect(() => {
    const sampleErrors = validateFileForErrors(allowedFormats, sampleFile, true);
    // console.log("sampleErrors", sampleErrors);
    // updateFileErrors(sampleErrors, customId);
  }, [allowedFormats, sampleFile, customId]);

  return (
    <>
      <div className="input-component">
        {/* UPLOAD SAMPLE FILE */}
        <div className="register-info-right-profile-info-input-title">
          <span>{inputTitle}</span>
        </div>
        <div>
          <input
            type="file"
            placeholder="Upload file"
            id={`${customId}UploadInput`}
            style={{ display: "none" }}
            onChange={(e) => handleSampleFileUpload(e.target.files[0])}
            accept={allowedFormatsSmall}
          />
          <div className="overall-upload-container" fieldName={allowedFormats}>
            <UploadPicture
              file="file"
              title={t("datasetUploadDataFile")}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                document.getElementById(`${customId}UploadInput`).click();
              }}
              texts={sampleFile?.name ? [] : [`${t("datasetFileSizeUniversal") + ": " + maxFileSize / 1024 / 1024 + " MB"}`, allowedFormatsString]}
              // texts={[t("datasetFileSize"), allowedFormatsString]}
              // texts={[t("datasetFileSizeUniversal") + ": " + maxFileSize / 1024 / 1024 + " MB"]}
              customText={sampleFile?.name ? sampleFile?.name : ""}
              customTextClassName={sampleFile?.name ? "overall-file-upload-text-filename focus-red" : ""}
              customTextOnClick={() => {
                if (sampleFile?.name) {
                  handleDownload(`/npp-test/${sampleFile?.name}`, sampleFile?.name);
                }
              }}
            />
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  width: 15,
                  height: 15,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Tooltip
                  text={
                    fileErrors[customId] && fileErrors[customId].length
                      ? fileErrors[customId].map((e) => t(e)).join(",\n")
                      : `${t("datasetFileSizeUniversal") + ": " + maxFileSize / 1024 / 1024 + " MB"} ${allowedFormatsString}`
                  }
                >
                  <img
                    alt="alt"
                    src="/assets/img/question.svg"
                    className={`tooltip-img ${fileErrors[customId] ? "red-filter" : ""}`}
                  />
                </Tooltip>
              </div>
            </div>
            {sampleFile?.name && (
              <div
                className="delete-button-container"
                style={{
                  marginLeft: "auto",
                  marginRight: "2em",
                  minWidth: "fit-content",
                }}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleRemoveSampleFile();
                }}
              >
                <img
                  alt="alt"
                  className="manaskopas-main-tb1-tr2-td3-right-part2-sub2"
                  src="/assets/img/bin.svg"
                />
                <span
                  className="manaskopas-main-tb1-tr2-td3-right-part2-font2"
                  style={{ textDecoration: "underline" }}
                  onClick={() => handleRemoveSampleFile()}
                >
                  <Trans i18nKey="deleteFile" />
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FileBox;
