import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { StateContext } from "../../../context";
import { useTranslation } from "react-i18next";
import { formatLatvianDate } from "../../card/components/dataholder";
import { highlightKeyword } from "../../search/search";
import { sanitize } from "dompurify";

const escapeHtml = (unsafe) => {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
};

const highlightTitle = (title, keyword, foregroundColor, backgroundColor) => {
  if (!keyword) return "<span>" + escapeHtml(title) + "</span>";
  // returns html with highlighted keyword
  const words = escapeHtml(title).split(" ");
  const highlighted = highlightKeyword(words, escapeHtml(keyword), foregroundColor, backgroundColor);
  return highlighted;
}

const CatalogList = ({ data, searchKeyword }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { language, contrast } = useContext(StateContext);

  const [foregroundColor, setForegroundColor] = useState("yellow");
  const [backgroundColor, setBackgroundColor] = useState("black");

  // useEffect(() => {
  //   const tiltContainer = document.querySelector('.tilt-container');
  
  //   if (tiltContainer) {
  //     const handleMouseMove = (e) => {
  //       const { width, height, left, top } = tiltContainer.getBoundingClientRect();
  //       const x = (e.clientX - left) / width - 0.5;
  //       const y = (e.clientY - top) / height - 0.5;
      
  //       // tiltContainer.style.transform = `rotateX(${y * 30}deg) rotateY(${x * -30}deg)`;
  //       tiltContainer.style.transform = `rotateY(${x * 50}deg) rotateX(${y * -50}deg) scale(1.02)`;
        
  //       const shadowX = x * -60;
  //       const shadowY = y * -60;
  //       const blur = 20;
  //       tiltContainer.style.boxShadow = `${shadowX}px ${shadowY}px ${blur}px rgba(0, 0, 0, 0.4), ${shadowX}px ${shadowY}px ${70}px rgba(0, 0, 0, 0.2)`

  //       const innerShadowX = x * -60;
  //       const innerShadowY = y * -60;

  //       tiltContainer.style.boxShadow += `, inset ${innerShadowX}px ${innerShadowY}px 30px rgba(255, 255, 255, 0.3)`;

  //     };
  
  //     const handleMouseLeave = () => {
  //       tiltContainer.style.transform = 'rotateX(0deg) rotateY(0deg)';
  //       tiltContainer.style.boxShadow = 'none';
  //     };
  
  //     tiltContainer.addEventListener('mousemove', handleMouseMove);
  //     tiltContainer.addEventListener('mouseleave', handleMouseLeave);
  
  //     return () => {
  //       tiltContainer.removeEventListener('mousemove', handleMouseMove);
  //       tiltContainer.removeEventListener('mouseleave', handleMouseLeave);
  //     };
  //   }
  // }, []);
  

  useEffect(() => {
    if (contrast !== "contrast_blue") {
      if (contrast === "contrast_yellow") {
        setForegroundColor("yellow");
        setBackgroundColor("black");
      }
      if (contrast === "contrast_blackwhite") {
        setForegroundColor("white");
        setBackgroundColor("black");
      }
      if (contrast === "contrast_blackyellow") {
        setForegroundColor("black");
        setBackgroundColor("yellow");
      }
    } else {
      setForegroundColor("yellow");
      setBackgroundColor("black");
    }
  }, [contrast]);

  const renameDistributionMode = (mode) => {
    switch (mode) {
      case "api":
        return t("datasetCardValueapi");
      case "file":
        return t("datasetCardValuefile");
      default:
        return mode;
    }
  };
  return data.map((item) => (
    <div
      className="catalog-main-menu-form tilt-container focus-red"
      key={item.nid}
      onClick={() => navigate(`/card/${item.uuid}`, { state: { from: location.pathname } })}
      onKeyDown={(event) => {
        if(event.key === "Enter"){
          navigate(`/card/${item.uuid}`, { state: { from: location.pathname } });
        }
      }}
      style={{ cursor: "pointer" }}
      tabIndex={0}
    >
      <div
        className="catalog-main-menu-form-title"
        dangerouslySetInnerHTML={{
          __html:
            sanitize(
              highlightTitle(
                language === "lv" ? item.field_dataset_name_lv : item.field_dataset_name,
                searchKeyword,
                foregroundColor,
                backgroundColor
              ))
        }}
      />
      <div className="catalog-main-menu-form-group">
        <div className="catalog-main-menu-form-text">{language === "lv" ? item.field_content_category : item.field_content_category_en_dummy}</div>
      </div>
      <div className="catalog-main-menu-form-group">
        <div className="catalog-main-menu-form-text1">
          {renameDistributionMode(item.field_distribution_mode ?? "")}
        </div>
        <div className="pulse-animation">
          <img alt="alt" className="catalog-main-menu-form-statusicon" src="/assets/img/state.png" />
        </div>
      </div>
      {item.field_dzesanas_datums ? (
        <div className="catalog-main-menu-form-group">
          <div className="catalog-main-menu-form-text1">
            {t("datasetDeletableShort")}
            {formatLatvianDate(item.field_dzesanas_datums)}
          </div>
        </div>
      ) : (
        item.field_changed_date && (
          <div className="catalog-main-menu-form-group">
            <div className="catalog-main-menu-form-text1">{`${t("updatedShort")}: ${formatLatvianDate(item.field_changed_date, true)}`}</div>
          </div>
        )
      )}
    </div>
  ));
};

export default CatalogList;
