import { DataBox } from ".";
import React from "react";
import Field from "../../../common/atoms/textfield";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { formatLatvianDate } from "../../card/components/dataholder";

const InfoContainer = ({ isNew, defval, status, visibility }) => {
  const { t } = useTranslation();
  const { distribution_mode } = defval;

  const children = [
    <>
      {/* <Field title={t("datasetAddPublicationUID")} text={defval?.distribution_uid ?? "-"} /> */}
      <Field title={t("datasetAddDatasetUID")} text={defval?.nid ?? "-"} />
      <Field
        title={t("datasetAddPublicationStatus")}
        text={
          defval?.data_set_statuss
            ? status && typeof status === "object"
              ? status.find((v) => v.key === defval.data_set_statuss)?.value
              : defval.data_set_statuss
            : "Jauna datu kopa"
        }
      />
      <Field
        title={t("datasetAddVisibility")}
        text={
          defval?.dataset_visibility
            ? visibility && typeof visibility === "object"
              ? visibility.find((v) => v.key === defval.dataset_visibility)?.value
              : defval.dataset_visibility
            : "-"
        }
      />

      {/* <Field title={t("datasetAddPublicationMode")} text={defval?.data_set_mode ?? "-"} /> */}
    </>,
    <>
      <Field
        title={t("datasetAddPublishedFrom")}
        text={defval?.published_from ? formatLatvianDate(defval?.published_from) : "-"}
      />
      <Field title={t("datasetAddNextUpdate")} text={defval?.next_update ?? "-"} />
      <Field
        title={t("dataSetUpdated") + ":"}
        text={defval?.changed ? formatLatvianDate(moment.unix(defval?.changed)) : "-"}
      />
      {distribution_mode === "file" ? (
        <>
          <Field title={t("datasetAddAPIKey")} text={defval?.publishing_api_key} />
          <Field title={t("datasetAddFileURL")} text={`/api/pull/metadata/${defval?.nid}`} />
          <Field title={t("datasetAddMetadataUpdateUrl")} text={"/client/update/metadata"} />
        </>
      ) : (
        <>
          <Field title={t("datasetAddAPIKey")} text={defval?.publishing_api_key} />
          {defval?.data_set_mode === "sandbox" && (
            <Field title={t("datasetAddSnapshotMQTTUrl")} text={defval?.sandbox_mqtt_topic} />
          )}
          <Field title={t("datasetAddMQTTTopicSource")} text={defval?.source_mqtt_topic} />
        </>
      )}
    </>,
  ];

  return <DataBox children={children} />;
};

export default InfoContainer;
