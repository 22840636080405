import { useEffect, useState } from "react";
import { DataProvide, NotificationBanner } from "../../common";
import Categories from "./components/categories";
import SlideBar from "./components/slidebar";
import { fetch } from "../../classes/auth";
import { sanitize } from "dompurify";
import { t } from "i18next";

import { useContext } from "react";
import { StateContext } from "../../context";
import { useNavigate } from "react-router-dom";
import { usr } from "../../classes/user";

const data = [
  {
    id: "1",
    title: "Latvijas Valsts Ceļi",
    href: "lvceli.lv",
    img: "/assets/img/lvc-logo.svg",
  },
  {
    id: "2",
    title: "Latvijas Vides ģeoloģijas un meteoroloģijas centrs",
    href: "videscentrs.lvgmc.lv",
    img: "/assets/img/LVGlogo.png",
  },
];

const Title = () => {
  const { language, handleModalOpen } = useContext(StateContext);

  const [infoMessages, setInfoMessages] = useState([]);
  const [alertMessages, setAlertMessages] = useState([]);

  const params = new URLSearchParams(window.location.search);
  const redirectTo = params.get("redirectTo");

  const navigate = useNavigate();

  //handleModalOpen("auth", { redirectTo: lang + "/" + uri });

  useEffect(() => {
    console.log("Title useEffect", redirectTo);

    if (!redirectTo) {
      return;
    }

    if (!usr?.sub) {
      handleModalOpen("auth", { redirectTo });
      return;
    }
    navigate(`/${redirectTo}`);
    // navigate un handleModalOpen ir pietaisīti
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectTo]);

  useEffect(() => {
    const fetchData = async () => {
      console.log(process.env.REACT_APP_CMS_API + process.env.REACT_APP_NOTIFICATIONS);
      try {
        fetch(process.env.REACT_APP_CMS_API + language + "/" + process.env.REACT_APP_NOTIFICATIONS, {})
          .then((response) => response.json())
          .then((data) => {
            const infoList = data.filter((item) => item.type === "information");
            infoList.sort((a, b) => b.created - a.created);
            setInfoMessages(infoList);

            const alertList = data.filter((item) => item.type === "problem");
            alertList.sort((a, b) => b.created - a.created);
            setAlertMessages(alertList);
          })
          .catch((error) => {});
      } catch (error) {}
    };

    fetchData();
  }, [language]);

  const handleCloseInfoMessage = (index) => {
    const updatedMessages = [...infoMessages];
    updatedMessages.splice(index, 1);

    setInfoMessages(updatedMessages);
  };

  const handleCloseAlertMessage = (index) => {
    const updatedMessages = [...alertMessages];
    updatedMessages.splice(index, 1);

    setAlertMessages(updatedMessages);
  };

  return (
    <div className="component-wrapper">
      {alertMessages.map((item, index) => {
        return (
          <NotificationBanner
            key={index}
            onClose={() => handleCloseAlertMessage(index)}
            svg="alret"
            backgroundColor="#9e313a"
            text={<div dangerouslySetInnerHTML={{ __html: sanitize(item.message.replace(/<\/?p>/g, "")) }} />}
          />
        );
      })}

      {infoMessages.map((item, index) => {
        return (
          <NotificationBanner
            key={index}
            onClose={() => handleCloseInfoMessage(index)}
            svg="alret"
            backgroundColor="#00CCF5"
            text={<div dangerouslySetInnerHTML={{ __html: sanitize(item.message.replace(/<\/?p>/g, "")) }} />}
          />
        );
      })}

      <SlideBar />
      <Categories />
      {(window.location.href.includes("test") || process.env.NODE_ENV === "development") && (
        <DataProvide data={data} limit={2} title={t("titleDataProvidedBy")} />
      )}
    </div>
  );
};

export default Title;
