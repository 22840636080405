import { decode } from "js-base64";
import { SSO_URL } from "./api";
import { usr } from "./user";
class Auth {
  queue = [];
  _tokens = {};
  tokenRefreshInterval = null;
  wasAuthorized = false;
  constructor() {
    this.handleModalOpen = () => {};
    this.setupTokenSharingEventListener();
    this.setupTokenLoadingEventListener();
    this.loadInitialTokens();
  }

  setupTokenSharingEventListener() {
    window.addEventListener("storage", (event) => {
      if (event.key === "getTokens") {
        console.log("setupTokenSharingEventListener", "sending tokens");
        const tokens = this?.tokens;
        console.log("setupTokenSharingEventListener", tokens);
        if (tokens && !this.isTokensExpired()) {
          localStorage.setItem("sharedTokens", JSON.stringify(tokens));
          // removing after 50 ms to allow requesting tab to actually load them.
          // without any timeout the requesting tab wont be able to load them in time
          setTimeout(() => localStorage.removeItem("sharedTokens"), 50);
        }
      }
    });
  }

  setupTokenLoadingEventListener() {
    window.addEventListener("storage", (event) => {
      if (event.key === "sharedTokens") {
        // do not load if the tokens are already in session storage
        if (sessionStorage.getItem("tokens")) {
          return;
        }

        console.log(
          "setupTokenLoadingEventListener",
          "received tokens",
          localStorage.getItem("sharedTokens")?.substring(0, 50)
        );

        const tokens = event.newValue ? JSON.parse(event.newValue) : null;

        if (tokens) {
          this.tokens = tokens;

          // if tokens are expired just logout and do not continue the code
          if (this.isTokensExpired()) {
            this.logout();
            return;
          }

          this.startTokenRefreshTimeout(tokens.expires_in * 1000);

          usr.refresh();
        }
      }
    });
  }

  loadInitialTokens() {
    const storedTokens = sessionStorage.getItem("tokens");
    console.log("loadInitialTokens", storedTokens?.substring(0, 50));
    if (storedTokens) {
      // tokens are in session storage
      this._tokens = JSON.parse(storedTokens);
      if (this.isTokensExpired()) {
        this.handleSessionInvalidation();
      }
    } else {
      // if not, try to ask from other tabs using localStorage
      localStorage.setItem("getTokens", Date.now().toString());
      console.log("loadInitialTokens", "asking for tokens");
    }
  }

  async setHandleModalOpen(handleModalOpen) {
    this.handleModalOpen = handleModalOpen;
  }

  startTokenRefreshTimeout(expires_in) {
    // this should help with the constant logout issue
    const adjustedExpiresIn = Math.max(expires_in - 10000, 10000);
    this.tokenRefreshInterval = setTimeout(async () => {
      await this.refetchTokens();
    }, adjustedExpiresIn);
  }

  stopTokenRefreshInterval() {
    clearInterval(this.tokenRefreshInterval);
    this.tokenRefreshInterval = null;
  }

  async fetchTokens(params, fetchedFromLogin) {
    try {
      const response = await fetch(`${SSO_URL}/realms/npp/protocol/openid-connect/token`, {
        method: `POST`,
        headers: {
          Accept: `application/json`,
          "Content-Type": `application/x-www-form-urlencoded`,
        },
        body: new URLSearchParams({
          client_id: "NPP",
          ...params,
        }),
      });
      if (!response.ok || response.status !== 200) {
        throw new Error(`HTTP error: ${response.status}`);
      }

      const tokens = await response.json();
      if (!tokens.access_token || !tokens.refresh_token) {
        throw new Error(`Invalid tokens: ${JSON.stringify(tokens)}`);
      }

      this.tokens = tokens;
      this.startTokenRefreshTimeout(tokens.expires_in * 1000);
    } catch (e) {
      // if response fails then keycloak ended the session (unless it's a login attempt)
      console.error(e);
      if (!fetchedFromLogin) {
        this.handleSessionInvalidation();
      } else {
        throw e;
      }
    }
  }

  async refetchTokens() {
    await this.fetchTokens({
      grant_type: "refresh_token",
      refresh_token: this.tokens.refresh_token,
    });
  }

  async handleSessionInvalidation() {
    await this.logout();
    this.handleModalOpen("tokensexpired");
    this.wasAuthorized = false;
  }

  set tokens(tokens) {
    tokens.expires_at = Date.now() + tokens.expires_in * 1000;
    tokens.refresh_expires_at = Date.now() + tokens.refresh_expires_in * 1000;
    this._tokens = tokens;
    sessionStorage.tokens = JSON.stringify(tokens);
  }

  get tokens() {
    return this._tokens || JSON.parse(sessionStorage.getItem("tokens") || "{}");
  }

  async getToken(token_type = "access_token") {
    if (!this.isAuthorized()) {
      if (this.wasAuthorized) {
        this.handleSessionInvalidation();
      }
      return undefined;
    }
    if (this.queue.length)
      return new Promise((resolve, reject) => this.queue.push({ resolve, reject, token_type }));
    this.queue.push({});
    this.queue.forEach(({ resolve, token_type }) => resolve?.(this.tokens?.[token_type]));
    this.queue = [];
    return this?.tokens?.[token_type];
  }

  getUserData() {
    const token = this.tokens?.access_token;
    const _pay = token?.split?.(".")?.[1],
      payload = _pay ? JSON.parse(decode(_pay)) : null;
    return payload;
  }
  async login({ username, password }) {
    try {
      await this.fetchTokens(
        {
          grant_type: "password",
          scope: "openid",
          username,
          password,
        },
        true
      );
      this.wasAuthorized = true;
    } catch (e) {
      console.error(e);
      console.error("Login failed");
    }
  }

  isTokensExpired() {
    const now = Date.now();
    const expires_at = this.tokens?.expires_at || 0;
    const refresh_expires_at = this.tokens?.refresh_expires_at || 0;
    return now > expires_at || now > refresh_expires_at;
  }

  isAuthorized() {
    const { expires_at } = this.tokens || {};

    if (!expires_at || Date.now() > expires_at || !this.tokens) {
      if (this.wasAuthorized) this.handleSessionInvalidation();
      return false;
    }

    return true;
  }

  async getAuthHeaders() {
    const token = await this.getToken();
    return token ? { Authorization: `Bearer ${token}` } : {};
  }

  async logout() {
    this.stopTokenRefreshInterval();
    this._tokens = {};
    sessionStorage.removeItem("tokens");
    usr.empty();
    this.wasAuthorized = false;
  }
}
const auth = new Auth();
const _fetch = async (url, options) =>
  fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      ...(await auth.getAuthHeaders()),
    },
  });
export { auth, _fetch as fetch };
