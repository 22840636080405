import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import VeikModal from "../../../modals/veikmodal";
import { StateContext } from "../../../context";
import { auth } from "../../../classes/auth";
import DeleteModal from "../../../modals/deletemodal";
import { Trans } from "react-i18next";
import { usr as user } from "../../../classes/user";
import { t } from "i18next";
import moment from "moment-timezone";


export const formatLatvianDate = (date, addOffset = false) => {
  if (!date) return "";
  // if not convertable by moment
  if (moment(date, "YYYY-MM-DDTHH:mm:ssZ").format("YYYY") === "Invalid date") return date;

  if (addOffset) {
    const dateWithoutOffset = moment.parseZone(date).format("YYYY-MM-DDTHH:mm:ss");
    const rigaDateWithAddedOffset = moment.utc(dateWithoutOffset).tz('Europe/Riga').format("DD.MM.YYYY HH:mm");
    return rigaDateWithAddedOffset;
  }

  return moment(date, "YYYY-MM-DDTHH:mm:ssZ").format("DD.MM.YYYY HH:mm");
};

export const navigateToCatalog = (navigate, location) => {
  const previousPath = location.state?.from || '/';
  
  if (previousPath === "/catalog") {
    navigate(-1);  // Navigate back if the previous page is /catalog
  } else {
    navigate("/catalog");  // Navigate to /catalog otherwise
  }
};

const DataHolder = ({ data, isPublic, isSubbed, refetch }) => {
  const { handleModalOpen, language } = useContext(StateContext);
  const navigate = useNavigate();
  const location = useLocation();
  const cantSubcribe = auth.isAuthorized() && !data?.field_files && !data?.field_download_url && data?.field_sipr_dataset_uid !== "1"

  const [isSubbedOptimistic, setIsSubbedOptimistic] = useState(isSubbed);

  const customRefetch = async () => {
    setIsSubbedOptimistic(false);
    await refetch();
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!auth.isAuthorized()) {
      handleModalOpen("auth");
      return;
    };

    handleModalOpen("veik", { nid: data?.nid, uuid: data?.uuid });
  };

  useEffect(() => {
    setIsSubbedOptimistic(isSubbed);
  }, [isSubbed]);

  return (
    <div className="card-content-left w3-col">
      <div className="card-content-left-box">
        <div className="card-content-left-owner">
          <div className="card-content-left-owner-title">
            <span style={{ width: "100%"}}>
              <Trans i18nKey="datasetOwner" />
            </span>
          <img alt="alt" className="card-content-left-owner-mark" src="/assets/img/card-mark.png" />
          </div>
          <div className="card-content-left-owner-elements">
          </div>
          {data.field_dzesanas_datums && (
            <div className="card-content-left-owner-update-period">
              <img alt="alt" src="/assets/img/warn.svg" />
              <span className="card-content-left-owner-update-period-label">
                <Trans i18nKey="datasetDeletable" />
              </span>
              &nbsp;
              <span>
                <b>{formatLatvianDate(data.field_dzesanas_datums)}</b>
              </span>
            </div>
          )}
          <div className="card-content-left-owner-update-period">
            <img alt="alt" src="/assets/img/refresh.svg" />
            <span className="card-content-left-owner-update-period-label">
              <Trans i18nKey="datasetUpdateFreq" />
            </span>
            &nbsp;
            <span>
              <b>{data.field_update_frequency ?? ""}</b>
            </span>
          </div>
          <div className="card-content-left-owner-update-data">
            <span>
              <Trans i18nKey="datasetPublished" />
              &nbsp;
              <b>{data?.field_published_from_1 ? formatLatvianDate(data.field_published_from_1) : formatLatvianDate(data?.created)}</b>
            </span>
            {data.field_changed_date && (
              <span>
                <Trans i18nKey="datasetLastChanges" />
                &nbsp;
                <b>{formatLatvianDate(data.field_changed_date, true)}</b>
              </span>
            )}
            {data?.field_score && data.field_score !== "0" && (
              <span>
                <Trans i18nKey="datasetScore" /> <b>{data.field_score}</b>
              </span>
            )}
          </div>
          {isPublic && !isSubbedOptimistic && !user?.is("Datu devējs") && (
            <div className="card-content-left-owner-subscribe">
              <button
                className={`card-content-owner-subscribe-button ${cantSubcribe ? "disabled" : ""} focus-red`}
                onClick={handleSubscribe}
                disabled={cantSubcribe}
              >
                <img alt="alt" src="/assets/img/voice.svg" />
                <Trans i18nKey="datasetSubscribe" />
              </button>
              {cantSubcribe && <p className="cant-sub-text">{t("datasetCantSub")}</p>}
            </div>
          )}

          {isSubbedOptimistic && (
            <div className="card-content-left-owner-unsubscribe">
              <button
                onClick={() => handleModalOpen("delete", { id: data?.nid, uuid: data?.uuid })}
                style={{ display: "flex", alignItems: "center" }}
              >
                <img alt="alt" src="/assets/img/voice.svg" />
                <span>{t("unSubscribeLong")}</span>
              </button>
            </div>
          )}
          <DeleteModal modalTtile={t("unSubscribeLong")} refetch={customRefetch} buttonTitle={t("unSubscribe")} />
          <VeikModal setIsSubbedOptimistic={setIsSubbedOptimistic} />
        </div>
        <div
          className="card-content-back focus-red"
          tabIndex={0}
          onClick={() => { navigateToCatalog(navigate, location)}} style={{ cursor: "pointer" }}
          onKeyDown={(event) => {
            if(event.key === "Enter"){
              navigateToCatalog(navigate, location)
            }
          }}
        >
          <div className="card-content-back-content">
            <img alt="alt" src="/assets/img/next.svg" />
            <span>
              <b>
                <u className="card-content-back-content-text">
                  <Trans i18nKey="datasetToCatalog" />
                </u>
              </b>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataHolder;
