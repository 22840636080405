import { useContext, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getCms, postCms } from "../../classes/cmsApi";
import { Banner } from "../../common";
import DataHolder, { navigateToCatalog } from "./components/dataholder";
import InfoBox from "./components/infobox";
import { StateContext } from "../../context";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../common/loader";
import { t } from "i18next";
import { usr as user } from "../../classes/user";
import { unquotify } from "../search/search";

const skipTranslationKeys = ["field_language_content"];

export const translateValue = (field) => {
  if (!field) return "";

  const dateRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/; // ISO 8601 date
  const uuidRegex = /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/i; // UUIDs

  if (dateRegex.test(field) || uuidRegex.test(field) || !isNaN(parseFloat(field))) {
    return field;
  }
  const fieldToTranslate = "datasetCardValue" + field.replace(/\s/g, "").replace(/[/()[\],.\-:]/g, "");
  const translation = t(fieldToTranslate);
  return translation === fieldToTranslate ? field : translation;
};

const fetchCardData = async (language, id, user, hasPostedView) => {
  const returnData = { cardData: {}, isSubbed: false };
  // Izgūst metadatu datus no CMS
  const rawData = await getCms("public/metadata/data", language, { uuid: id ?? "" });
  if (rawData?.error) {
    console.error(rawData.error);
    throw new Error(rawData.error);
  }

  console.log("rawData", rawData);

  // https://admin-test.lvceli.lv/api/v1/get/dataset/metrics/7300

  // this recieves "datu atjaunošanas date" un "vērtējums"
  // the rawData endpoint no longer provides correct values for these 2 fields
  // ALSO: getCms can not be used because it automaticially adds the language parameter and this is not supported by the endpoint
  const metricsData = await fetch(
    `${window.location.origin}/api/v1/get/dataset/metrics/${rawData[0].nid}`
  ).then((res) => res.json());

  rawData[0].field_update_datetime = metricsData?.data?.update_metrics?.changed_date;
  rawData[0].field_changed_date = metricsData?.data?.update_metrics?.changed_date;
  
  rawData[0].field_score =
    metricsData?.data?.rating_metrics?.average_rating != null
      ? String(metricsData.data.rating_metrics.average_rating)
      : null;

  // Translate data before returning
  const dataBeforeTranslation = rawData[0] ?? {};
  const translated = Object.keys(dataBeforeTranslation).reduce((acc, key) => {
    if (skipTranslationKeys.includes(key)) {
      acc[key] = dataBeforeTranslation[key];
      return acc;
    }
    const translatedValue = translateValue(dataBeforeTranslation[key]);
    const unquotedValue = unquotify(translatedValue);
    acc[key] = unquotedValue;
    return acc;
  }, {});

  returnData.cardData = translated;

  if (!hasPostedView.current && rawData?.[0]?.nid) {
    try {
      postCms("api/v1/add/view", { nid: rawData[0].nid });
    } catch (e) {}
    hasPostedView.current = true;
  }

  // Atjauno lietotāja informāciju un pārbauda lietotāja abonēto datu kopu sarakstu
  await user?.refresh();
  if (user?.can("Datu ņēmēja abonētās  datu kopas")) {
    const userSubbed = await getCms("api/v1/subcribed/check/" + rawData[0].nid);
    if (!userSubbed.error) {
      returnData.isSubbed = userSubbed.subscribed;
      returnData.apiKey = userSubbed.api_key;
    }
  }
  return returnData;
};

export const useCardData = (language, id, user) => {
  const hasPostedView = useRef(false);

  const { data, isLoading, isError, error, refetch } = useQuery(
    [`card`, id, language],
    async () => {
      if (!id) return { cardData: {}, isSubbed: false };
      return await fetchCardData(language, id, user, hasPostedView);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  return { data, isLoading, isError, error, refetch };
};

const Card = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { language } = useContext(StateContext);
  const { id } = useParams();

  const {
    data = { cardData: {}, isSubbed: false },
    isLoading,
    isError,
    error,
    refetch,
  } = useCardData(language, id, user);

  useEffect(() => {
    // scrolls to top of the page when component is mounted for components that are pre-loaded
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [id]);

  if (isLoading) return <Loader />;

  const { isSubbed, apiKey, cardData } = data ?? { isSubbed: false, apiKey: "", cardData: {} };

  // cardData.name = "Datu kopas nosaukums, kas tiks attēlots kartes virsrakstā";

  if (!cardData || Object.keys(cardData).length === 0) {
    // return <Banner bannerData={{ title: "Nav atrasta datu kopa" }} />;
    <div className="catalog-main-menu no-data">
      <span className="page-error-message">{t("noData")}</span>
      <img alt={t("noData")} src={`/assets/img/errorguy.png`} />
    </div>;
  }
  if (isError) {
    console.error(error);
    return (
      <div className="catalog-main-menu no-data">
        <span className="page-error-message">{t("noData")}</span>
        <img alt={t("noData")} src={`/assets/img/errorguy.png`} />
      </div>
    );
  }

  const bannerData = {
    title: language === "lv" ? cardData.field_dataset_name_lv : cardData.field_dataset_name,
    path1: t("workspaceStart"),
    path1Url: "/",
    path2: t("headerDataCatalog"),
    path2Url: "/catalog",
    path2NavigateFunction: () => navigateToCatalog(navigate, location),
  };

  return (
    <>
      <Banner bannerData={bannerData} />
      <div className="card">
        <div className="card-content w3-row">
          <DataHolder
            data={cardData}
            isPublic={cardData.field_data_set_statuss === "Publicēta"}
            isSubbed={isSubbed}
            refetch={refetch}
          />
          <div className="card-content-detail w3-rest">
            <InfoBox data={cardData} apiKey={apiKey} isSubbed={isSubbed} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
