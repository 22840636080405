import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { countries } from "../../../countries";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import Tooltip from "../../../common/atoms/tooltip";
import PropTypes from "prop-types";

import { t } from "i18next";
import { Dropdown, Input } from "../../manaskopas/components";
import { StateContext } from "../../../context";

const getPlaceholderValue = (fieldName) => {
  switch (fieldName) {
    case "email":
      return t("email");
    case "name":
      return t("registrationJuridicalName");
    case "regNr":
      return t("registrationJuridicalRegNr");
    case "address":
      return t("registrationJuridicalAddress");
    case "firstName":
      return t("registrationJuridicalName");
    case "lastName":
      return t("registrationJuridicalLastName");
    case "phone":
      return t("ContactpersonPhoneNumber");
    case "webpage":
      return t("registrationJuridicalWebpage");
    case "pubDataInfo":
      return t("ContactpersonInfoAboutPublishedData");
    // Add more cases for additional fields
    default:
      return "";
  }
};

export const TextField = ({
  name,
  rules = {},
  placeholder = "",
  styles = {
    ok: "register-info-right-profile-info-input",
    nok: "register-info-right-profile-info-input register-info-right-profile-info-input-error",
  },
  tooltipText = "",
  isTextarea = false,
  customStyle = {},
  banners = {},
  register,
  errors,
  getValues,
  getFieldState,
  required,
  requiredMessage,
}) => {
  const { t } = useTranslation();

  const fieldHasError = name in errors || (name in banners && banners[name].ref === getValues(name));

  return (
    <>
      <div
        style={{
          marginBottom: 10,
          width: "27em",
          display: "flex",
          gap: "1em",
          alignItems: "center",
          flexDirection: "row",
        }}
        className={
          name in errors || (name in banners && banners[name].ref === getValues(name))
            ? styles.nok ?? `register-info-right-profile-info-input-error`
            : styles.ok ?? `register-info-right-profile-info-input`
        }
      >
        <div style={{ display: "flex", position: "relative", width: "100%" }}>
          {isTextarea ? (
            <textarea
              {...register(name, { ...rules })}
              placeholder={placeholder}
              style={{ width: "100%", ...customStyle }}
            />
          ) : (
            <input
              {...register(name, { ...rules })}
              placeholder={placeholder}
              style={{ width: "100%", ...customStyle }}
            />
          )}
        </div>

        {(getValues(name)?.length > 0 || getFieldState(name).isTouched) &&
        !(name in errors) &&
        (!(name in banners) || banners[name].ref !== getValues(name)) ? (
          <img
            alt="alt"
            src="/assets/img/check.svg"
            style={{
              height: "100%",
              display: "flex",
            }}
            className="checkmark"
          />
        ) : (
          <div>
            <Tooltip
              text={`${
                fieldHasError ? `${t(errors[name]?.message)}.\n Derīgas vērtības piemērs: ` : ""
              }${tooltipText}`}
              customStyle={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
                width: 15,
              }}
            >
              <img
                alt="alt"
                src="/assets/img/question.svg"
                className={`tooltip-img ${fieldHasError ? "red-filter" : ""}`}
              />
            </Tooltip>
          </div>
        )}
      </div>
      {required && getValues(name)?.length === 0 && (
        <div className="register-info-right-profile-info-description">{requiredMessage}</div>
      )}
    </>
  );
};
TextField.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  placeholder: PropTypes.string,
  styles: PropTypes.object,
  tooltipText: PropTypes.string,
  isTextarea: PropTypes.bool,
  customStyle: PropTypes.object,
  banners: PropTypes.object,
  register: PropTypes.func,
  errors: PropTypes.object,
  getValues: PropTypes.func,
  getFieldState: PropTypes.func,
};

const StepTwo = ({ setData, type, banners, setBanners }) => {
  const [ctx, setCtx] = useState(0);
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const { language } = useContext(StateContext);
  const { t } = useTranslation();
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    getFieldState,
    watch,
    trigger,
  } = useForm({ mode: `all` });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setCtx(ctx + 1);
    });
    return () => subscription.unsubscribe();
  }, [watch, ctx, setBanners, banners, getValues]);

  const isDirty = () => {
    let required = [`email`];
    if (type === 1) {
      required = [`email`, `name`, `regNr`, `address`, `firstName`, `lastName`, `phone`, `webpage`];
    }

    for (const req of required) {
      if (!getValues(req)) {
        return false;
      }
    }

    return true;
  };

  const country = watch("country");

  useEffect(() => {
    if (getFieldState("regNr").isDirty) {
      trigger("regNr");
    }
    if (getFieldState("phone").isDirty) {
      trigger("phone");
    }
  }, [country, trigger]);

  return (
    <div style={{ width: "70%" }}>
      <form onSubmit={handleSubmit(setData)}>
        <div className="register-info-right-header">
          <Trans i18nKey={type === 0 ? "registrationProfileInfoForm" : "registrationProfileInfoFormDD"} />
        </div>
        <div className="register-info-right-description">
          <Trans i18nKey={type === 0 ? "registrationProvideEmailForm" : "registrationProvideEmailFormDD"} />
        </div>
        <div style={{ marginTop: "40px" }}>
        {type === 1 && (
          <Dropdown
            style={{ marginTop: 20 }}
            fieldName="country"
            placeholder={t("registrationJuridicalCountry")}
            defval="LV"
            register={register}
            errors={errors}
            rules={{
              required: { message: "fieldIsRequired", value: true },
            }}
            // disabled={true} // LVC-2649
            banner={banners}
            tooltipText="Latvija"
            options={countries.map((country) => ({
              key: country.value,
              value: language === "lv" ? country.label : country.labelEn,
            }))}
            setValue={setValue}
            getValues={getValues}
            getFieldState={getFieldState}
          />
        )}
          <Input
            name="email"
            isMail
            rules={{
              required: {
                value: true,
                message: "fieldIsRequired",
              },
              pattern: {
                value:
                  /[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?){1,}/,
                message: "invalidEmail",
              },
            }}
            placeholder={type === 1 ? t("registrationEmail") : t("email")}
            tooltipText="janis.berzins@sia.com"
            banners={banners}
            register={register}
            errors={errors}
            getValues={getValues}
            getFieldState={getFieldState}
          />
          <div className="register-info-right-profile-info-description">
            <Trans i18nKey="emailWillBeUsedForOfficialCommunication" />
          </div>
        </div>
        {type === 1 && (
          <div className="register-info-right-profile-info" style={{ marginBottom: "1em" }}>
            <div style={{ marginTop: 40 }} className="register-info-right-profile-info-label">
              <Trans i18nKey="ContactpersonData" />
            </div>
            <Input
              name="firstName"
              rules={{
                required: { message: "fieldIsRequired", value: true },
              }}
              placeholder={t("ContactpersonFirstName")}
              tooltipText="Jānis"
              banners={banners}
              register={register}
              errors={errors}
              getValues={getValues}
              getFieldState={getFieldState}
            />
            <Input
              name="lastName"
              rules={{
                required: { message: "fieldIsRequired", value: true },
              }}
              placeholder={t("ContactpersonLastName")}
              tooltipText="Bērziņš"
              banners={banners}
              register={register}
              errors={errors}
              getValues={getValues}
              getFieldState={getFieldState}
            />
            <Input
              name="phone"
              rules={{
                required: {
                  value: true,
                  message: "fieldIsRequired",
                },
                validate: (val) => {
                  if (country !== "LV") {
                    return true; // do not validate phone if not from Latvia
                  }
                  return val.match(/^(\+371 ?)?\d{8}$/) ? true : "invalidPhone";
                },
              }}
              placeholder={t("ContactpersonPhoneNumber")}
              tooltipText={country === "LV" ? "+371 67000000" : "+1 (555) 123-4567"}
              banners={banners}
              register={register}
              errors={errors}
              getValues={getValues}
              getFieldState={getFieldState}
            />

            <div className="register-info-right-profile-info-label" style={{ marginTop: "3em" }}>
              <Trans i18nKey="registrationJuridicalInfo" />
            </div>

            <Input
              name="secondEmail"
              isMail
              rules={{
                required: {
                  value: true,
                  message: "fieldIsRequired",
                },
                pattern: {
                  value:
                    /[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?){1,}/,
                  message: "invalidEmail",
                },
              }}
              placeholder={t("organizationEmail")}
              tooltipText="SIA@sia.com"
              banners={banners}
              register={register}
              errors={errors}
              getValues={getValues}
              getFieldState={getFieldState}
            />
            <div className="register-info-right-profile-info-description">
              <Trans i18nKey="emailWillBePubliclyViewable" />
            </div>
            <div style={{ marginBottom: "1em" }} />
            <Input
              name="name"
              rules={{
                required: { message: "fieldIsRequired", value: true },
              }}
              placeholder={t("registrationJuridicalName")}
              tooltipText='SIA "Nosaukums"'
              banners={banners}
              register={register}
              errors={errors}
              getValues={getValues}
              getFieldState={getFieldState}
            />
            <Input
              name="regNr"
              rules={{
                required: { message: "fieldIsRequired", value: true },
                validate: (val) => {
                  if (country !== "LV") {
                    return true;
                  }
                  return val.length === 11 ? true : "invalidRegNrLength";
                },
              }}
              placeholder={t("registrationJuridicalRegNr")}
              tooltipText="40000000000"
              banners={banners}
              register={register}
              errors={errors}
              getValues={getValues}
              getFieldState={getFieldState}
            />
            <Input
              name="address"
              rules={{
                required: { message: "fieldIsRequired", value: true },
              }}
              placeholder={t("registrationJuridicalAddress")}
              tooltipText="Lejas iela 10-5, Līgatne, Cēsu nov."
              banners={banners}
              register={register}
              errors={errors}
              getValues={getValues}
              getFieldState={getFieldState}
            />
            <Input
              name="webpage"
              rules={{
                required: { message: "fieldIsRequired", value: true },
                pattern: {
                  value: /^((ftp|http|https):\/\/)?www\.([A-Za-z0-9-]+)\.([A-z]{2,})/,
                  message: "invalidWebpage",
                },
              }}
              placeholder={t("popupMessageWebsite")}
              tooltipText="www.sia.lv"
              banners={banners}
              register={register}
              errors={errors}
              getValues={getValues}
              getFieldState={getFieldState}
            />
            <Input
              big
              name="pubDataInfo"
              rules={{
                required: { message: "fieldIsRequired", value: true },
                maxLength: {
                  value: 255,
                  message: "maxSize255",
                },
              }}
              placeholder={t("ContactpersonInfoAboutPublishedData")}
              tooltipText={t("ContactpersonInfoAboutPublishedDataExample")}
              customStyle={{ height: "200px", fontSize: "18px" }}
              isTextarea={true}
              banners={banners}
              register={register}
              errors={errors}
              getValues={getValues}
              getFieldState={getFieldState}
            />
          </div>
        )}
        <div style={{ display: "flex" }}>
          <span
            className="authorize-main-left-cookies-rules"
            style={{ display: "inline", 
              whiteSpace: "normal" }}
          >
            {t("theSiteIsProtected")}
            <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
              {t("privacyPolicy")}
            </a>{" "}
            {t("theSiteIsProtectedAnd")}
            <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">
              {t("rules")}
            </a>
            .
          </span>
        </div>
        <button
          className={`register-info-right-next-profile-button${
            isDirty() && Object.keys(errors).length === 0 ? `` : `-disabled`
          } focus-red`}
          id="progress-next2-third"
          disabled={!isDirty() || Object.keys(errors).length}
          style={{
            marginTop: 20,
            height: 58,
            ...(isDirty() && Object.keys(errors).length === 0
              ? {}
              : { filter: `grayscale(1)`, cursor: `not-allowed` }),
          }}
        >
          <img alt="alt" src="/assets/img/next.svg" />
          <Trans i18nKey="registrationContinue" />
        </button>
      </form>
      <GoogleReCaptcha
        onVerify={(token) => {
          window.reCaptchaToken = token;
        }}
        refreshReCaptcha={refreshReCaptcha}
      />
    </div>
  );
};

export default StepTwo;
