import React, { useCallback, useEffect, useRef, useState } from "react";
import PrimaryButton from "../../../common/atoms/primarybutton";
import { t } from "i18next";
import Tooltip from "../../../common/atoms/tooltip";
import "./index.css";

import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import moment from "moment";
import { StyledSelect } from "./styledReactSelect";
import LabelTag from "../../../components/label";

const customLocale = {
  months: [
    t("january"),
    t("february"),
    t("march"),
    t("april"),
    t("may"),
    t("june"),
    t("july"),
    t("august"),
    t("september"),
    t("october"),
    t("november"),
    t("december"),
  ],
  weekdaysShort: [
    t("sunShort"),
    t("monShort"),
    t("tueShort"),
    t("wedShort"),
    t("thuShort"),
    t("friShort"),
    t("satShort"),
  ],
  weekdaysMin: [t("sunMin"), t("monMin"), t("tueMin"), t("wedMin"), t("thuMin"), t("friMin"), t("satMin")],
  weekdays: [
    t("sunday"),
    t("monday"),
    t("tuesday"),
    t("wednesday"),
    t("thursday"),
    t("friday"),
    t("saturday"),
  ],

  week: {
    dow: 1, // Monday is the first day of the week.
  },
  formats: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "DD.MM.YYYY",
    LL: "D. MMMM YYYY",
    LLL: "D. MMMM YYYY HH:mm",
    LLLL: "dddd, D. MMMM YYYY HH:mm",
  },
};

moment.updateLocale("custom", customLocale);

function MultipleDropdown({
  fieldName,
  defval = [],
  placeholder,
  register,
  errors,
  getValues = () => ({}),
  getFieldState = () => ({}),
  onChange = () => {},
  onBeforeChange,
  rules,
  options = [],
  title,
  tooltipText = "",
  setValue,
  resetOptions,
  disabled = false,
  ...props
}) {
  const [selectedOptions, setSelectedOptions] = useState(defval);
  const prevSelectedOptions = useRef(defval);
  const init = useRef(true);

  useEffect(() => {
    register(fieldName, {
      ...rules,
      value: selectedOptions,
    });
  }, [fieldName, register, rules, selectedOptions]);

  useEffect(() => {
    setValue(fieldName, selectedOptions);
    if (prevSelectedOptions.current !== selectedOptions) {
      prevSelectedOptions.current = selectedOptions;
      onChange(selectedOptions);
    }
  }, [selectedOptions, setValue, fieldName, onChange]);

  useEffect(() => {
    if (init.current) {
      init.current = false;
      return;
    }
    setSelectedOptions([]);
  }, [resetOptions]);

  const handleOptionSelect = useCallback(
    (option) => {
      setSelectedOptions((prev) => {
        const newValue = prev.includes(option) ? prev.filter((val) => val !== option) : [...prev, option];
        setValue(fieldName, newValue);
        register(fieldName, rules)?.onChange?.(newValue.length > 0 ? "ok" : undefined);
        return newValue;
      });
    },
    [fieldName, register, rules, setValue]
  );

  const getOptionValue = useCallback(
    (key) => {
      const foundOption = options.find((option) => `${option.key}` === `${key}`);
      return foundOption ? foundOption.value : key;
    },
    [options]
  );

  const hasError = errors?.[fieldName];

  return (
    <>
      <div {...props} className="input-component" name={fieldName} style={{marginTop: "20px"}}>
        {title && <div className="register-info-right-profile-info-input-title">{title}</div>}

        <div
          style={{
            display: "flex",
            gap: "1em",
            alignItems: "center",
            ...(!title && { gridColumnEnd: "span 2" }),
          }}
        >
          <div className="input-wrapper">
            <StyledSelect
              hasError={hasError}
              isMulti
              isSearchable
              defaultValue={selectedOptions.map((key) => {
                const option = options.find((opt) => `${opt.key}` === `${key}`);
                return option && option.key ? { key: option.key, value: option.value } : { key, value: key };
              })}
              value={selectedOptions.map((key) => {
                const option = options.find((opt) => `${opt.key}` === `${key}`);
                return option && option.key ? { key: option.key, value: option.value } : { key, value: key };
              })}
              controlShouldRenderValue={false}
              onChange={(selected) => {
                if (onBeforeChange) {
                  if (onBeforeChange(selected.map((item) => item.key))) {
                    return;
                  }
                }
                const newValue = selected ? selected.map((item) => item.key) : [];
                setSelectedOptions(newValue);
                register(fieldName, rules)?.onChange?.(newValue.length > 0 ? "ok" : undefined);
              }}
              options={options}
              noOptionsMessage={() => t("noOptions")}
              isOptionDisabled={(option) => selectedOptions.includes(option?.key)}
              getOptionLabel={(option) => option.value}
              getOptionValue={(option) => option?.key}
              isDisabled={disabled}
              placeholder={placeholder}
              classNamePrefix={
                hasError && !selectedOptions.length
                  ? "overall-input-dropdown-error"
                  : "overall-input-dropdown"
              }
              className={selectedOptions.length ? "" : "default-option"}
            />
          </div>
          {(selectedOptions.length > 0 || getFieldState(fieldName).isTouched) && !hasError ? (
            <img alt="checkmark" src="/assets/img/check.svg" className="checkmark" />
          ) : (
            <div style={{ width: 15, height: 15, display: "flex", justifyContent: "center" }}>
              {(errors[fieldName]?.message || tooltipText) && (
                <Tooltip text={hasError ? t(errors[fieldName]?.message) : tooltipText}>
                  <img
                    alt="question mark"
                    src="/assets/img/question.svg"
                    className={`tooltip-img ${hasError ? "red-filter" : ""}`}
                  />
                </Tooltip>
              )}
            </div>
          )}
        </div>
      </div>
      {selectedOptions.length ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            gap: "0.5em",
            marginTop: "0.75em",
            ...(!title && { gridColumnEnd: "span 2" }),
          }}
        >
          {selectedOptions.map((key) => (
            <LabelTag
              key={key}
              text={getOptionValue(key)}
              setText={getOptionValue(key)}
              onRemove={() => handleOptionSelect(key)}
              forceNotHovered={false}
              isInteractable={true}
              isDisabled={disabled}
              />
          ))}
        </div>
      ) : null}
    </>
  );
}

const DataBox = ({ children, label, ...props }) => {
  if (!children) return;

  return (
    <div
      style={{
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: "1fr 2fr",
        gap: "2em",
      }}
    >
      {label && (
        <div className="overall-databox-label">
          <span>{label}</span>
        </div>
      )}
      <div className="overall-databox" {...props} style={{ ...(!label && { gridColumnEnd: "span 2" }) }}>
        {children.map((child, index) => {
          if (!child) return null; // If child is not present, return null

          const isLastChild = index === children.length - 1;

          return (
            <React.Fragment key={index}>
              {child}
              {!isLastChild && <div className="overall-databox-devider" />}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

const Input = ({
  name,
  rules = {},
  placeholder = "Obligāts lauks",
  styles = {
    ok: "register-info-right-profile-info-input",
    nok: "register-info-right-profile-info-input register-info-right-profile-info-input-error",
  },
  title = "",
  register,
  errors = {},
  setValue = () => {},
  getValues = () => ({}),
  getFieldState = () => ({}),
  banners = {},
  big = false,
  disabled,
  maxLength = null,
  tooltipText = "",
  isMail = false,
  isDate = false,
  minDate = null,
  isPassword = false,
  onChange = () => {},
  customInputStyles = {},
  trigger = () => {},
  ...props
}) => {
  const hasError =
    (errors && typeof errors === `object` && name in errors) ||
    (name in banners && banners[name].ref === getValues(name));

  const [dateValue, setDateValue] = useState(
    getValues(name) ? moment(getValues(name), "DD.MM.YYYY HH:mm").toDate() : null
  );

  const handleChange = (e) => {
    if (!e || !e.target) return;
    setValue(name, e.target.value);
    onChange(e);
  };

  const handleChangeDate = (date) => {
    let dateStr = date?._i || date;
    const dateFormatRegex = /^\d{2}\.\d{2}\.\d{4} \d{2}:\d{2}$/;

    const regexCheck = dateFormatRegex.test(dateStr)
    const dateIsString = typeof dateStr === "string";


    if (dateIsString && !regexCheck) {
      setValue(name, date);
      trigger(name);
      return;
    }

    const momentDate = moment.isMoment(date) ? date : moment(date, "DD.MM.YYYY HH:mm", true);
  
    if (!momentDate.isValid()) {
      setValue(name, date);
      trigger(name);
      return;
    }
    if (minDate && momentDate.isBefore(moment(minDate, "DD.MM.YYYY HH:mm"))) {
      setValue(name, date);
      trigger(name);
      return;
    }
  
    setValue(name, momentDate.format("DD.MM.YYYY HH:mm"));
    trigger(name);
    setDateValue(momentDate.toDate());
    onChange(momentDate.format("DD.MM.YYYY HH:mm"));
  };
  
  return (
    <div
      className={`input-component ${
        name in errors || (name in banners && banners[name].ref === getValues(name))
          ? styles.nok ?? "register-info-right-profile-info-input-error"
          : styles.ok ?? "register-info-right-profile-info-input"
      }`}
      style={customInputStyles}
    >
      {title && <div className="register-info-right-profile-info-input-title">{title}</div>}
      <div
        style={{
          display: "flex",
          gap: "1em",
          alignItems: "center",
          ...(!title && { gridColumnEnd: "span 2" }),
          ...(isMail && { position: "relative" }),
        }}
      >
        {isDate ? (
          <div className="input-wrapper">
            <Datetime
              value={dateValue}
              {...register(name, rules)}
              onChange={handleChangeDate}
              inputProps={{ disabled }}
              locale="lv"
              dateFormat="DD.MM.YYYY"
              timeFormat="HH:mm"
              isValidDate={(current) => {
                if (minDate) {
                  return current.isAfter(moment(minDate, "DD.MM.YYYY HH:mm"));
                }
              }}
            />
          </div>
        ) : big ? (
          <textarea disabled={disabled} {...register(name, { ...rules })} placeholder={placeholder} />
        ) : (
          <input
            key={`input-${name}-${JSON.stringify(rules)}`}
            disabled={disabled}
            {...register(name, {
              ...rules,
              onChange: handleChange,
            })}
            placeholder={placeholder}
            maxLength={maxLength}
            {...(isPassword && { type: "password" })}
          />
        )}
        {(getValues(name)?.length > 0 || getFieldState(name).isTouched) && !hasError ? (
          <img alt="alt" src="/assets/img/check.svg" className="checkmark" />
        ) : (
          <div
            style={{
              width: 15,
              height: 15,
              display: "flex",
              justifyContent: "center",
            }}
          >
            {((hasError && errors[name]?.message) ||
              (!hasError && tooltipText) ||
              (name in banners && banners[name].ref === getValues(name))) && (
              <Tooltip
                text={`${
                  hasError
                    ? errors[name]?.message
                      ? t(errors[name]?.message)
                      : banners[name].message
                    : tooltipText
                }`}
              >
                <img
                  alt="alt"
                  src="/assets/img/question.svg"
                  className={`tooltip-img ${hasError ? "red-filter" : ""}`}
                />
              </Tooltip>
            )}
          </div>
        )}
      </div>

      {/* {hasError && <p className="error-message">{errors[name]?.message}</p>} */}
    </div>
  );
};

const BigInput = ({ placeholder, svg }) => {
  return <textarea aria-disabled={true} className="overall-input-big" placeholder={placeholder} />;
};

const UploadPicture = ({
  title,
  onClick,
  customText = "",
  customTextClassName = "",
  customTextOnClick = () => {},
  texts = [t("datasetFileSize")],
  showButton = true,
  customSvgForButton = "upload",
}) => {
  return (
    <>
      {showButton && (
        <PrimaryButton
          id="file-upload-button"
          onClick={(e) => {onClick(e)}}
          svg={customSvgForButton}
          title={title}
          imgClass={"overall-image-upload-svg"}
        />
      )}
      {customText &&
        <span
          className={`overall-file-upload-text ${customTextClassName}`}
          onClick={(e) => {customTextOnClick(e)}}
          tabIndex={0}
        >
          {t(customText)}
        </span>
      }
      {texts.map((text, index) => (
        <span key={index} className="overall-file-upload-text">
          {text}
        </span>
      ))}
    </>
  );
};

function Dropdown({
  fieldName,
  defval = [],
  placeholder,
  register,
  errors,
  setValue = () => {},
  getValues = () => ({}),
  getFieldState = () => ({}),
  rules,
  options = [],
  title,
  tooltipText = "",
  setSelectedCategory,
  resetOptions,
  onChange,
  onBeforeChange, // If returns true, then the change is not made
  disabled = false,
  optionalValueState,
  valueCanBeChangedExternally = false,
  ...props
}) {
  const hasError = errors && typeof errors === `object` && fieldName in errors;
  const [selectedOption, setSelectedOption] = useState(defval ?? "");
  const prevSelectedOption = useRef(defval ?? "");
  useEffect(() => {
    if (prevSelectedOption.current !== selectedOption) {
      prevSelectedOption.current = selectedOption;
      onChange?.(selectedOption);
    }
    getFieldState(fieldName).isTouched && register(fieldName, rules)?.onChange?.(selectedOption);
  }, [selectedOption, onChange, getValues, fieldName, getFieldState, register, rules]);

  useEffect(() => {
    setValue(fieldName, selectedOption, { shouldDirty: true });
  }, [selectedOption, setValue, fieldName]);

  // console.log(fieldName, selectedOption, getValues(fieldName));

  useEffect(() => {

    // if (fieldName === "legal_framework" || fieldName === "georeferencing_method") {
    if (valueCanBeChangedExternally) {
      // equal check to not cause infinite loop

      // if (JSON.stringify(optionalValueState) !== JSON.stringify(selectedOption)) {
        setSelectedOption(optionalValueState);
        prevSelectedOption.current = optionalValueState;
      // }
    }
    // eslint is needed to preved infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionalValueState]);
  


  const selectRef = useRef(null); // Create a ref to access react-select

  return (
    <div {...props} className="input-component" name={fieldName} style={{marginTop: "20px"}}>
      {title && <div className="register-info-right-profile-info-input-title">{title}</div>}
      <div
        style={{
          display: "flex",
          gap: "1em",
          alignItems: "center",
          minWidth: 0,
          ...(!title && { gridColumnEnd: "span 2" }),
        }}
      >
        <div className="input-wrapper">
          <StyledSelect
            ref={selectRef}
            defaultValue={() => options.find((option) => option?.key?.toString() === defval?.toString())}
            {...register(fieldName, rules)}
            isDisabled={disabled}
            options={options}
            value={options.find((option) => option.key === selectedOption)}
            onChange={(selected) => {
              if (!selected) {
                setSelectedOption("");
                register(fieldName, rules)?.onChange?.("");
                return;
              }
              
              if (onBeforeChange?.(selected.key)) {
                return;
              }
              setSelectedOption(selected.key);
              setSelectedCategory?.(selected.key);
              register(fieldName, rules)?.onChange?.(selected);
            }}
            getOptionLabel={(option) => option.value}
            getOptionValue={(option) => option.key}
            placeholder={placeholder}
          />
        </div>

        {(getValues(fieldName)?.length > 0 || getFieldState(fieldName).isTouched) && !hasError ? (
          <img alt="alt" src="/assets/img/check.svg" className="checkmark" />
        ) : (
          <div
            style={{
              width: 15,
              height: 15,
              display: "flex",
              justifyContent: "center",
            }}
          >
            {(errors[fieldName]?.message || tooltipText) && (
              <Tooltip text={hasError ? t(errors[fieldName]?.message) : tooltipText}>
                <img
                  alt="alt"
                  src="/assets/img/question.svg"
                  className={`tooltip-img ${hasError ? "red-filter" : ""}`}
                />
              </Tooltip>
            )}
          </div>
        )}
      </div>
      {/* {errors && errors[fieldName] && <p className="error-message">{errors[fieldName]?.message}</p>} */}
    </div>
  );
}

export { MultipleDropdown, UploadPicture, Input, DataBox, BigInput, Dropdown };
