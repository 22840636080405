import { DataBox, Dropdown, Input } from "..";
import Field from "../../../../common/atoms/textfield";
import CheckBox from "../../../../common/checkbox";
import { useTranslation } from "react-i18next";

const DataProviderInfo = ({
  defval,
  getValues,
  register,
  getFieldState,
  setValue,
  declarations,
  errors,
  isNew,
}) => {
  const { t } = useTranslation();

  const emailRules = {
    required: { message: "fieldIsRequired", value: true },
    pattern: {
      value:
        /[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?){1,}/,
      message: "invalidEmail",
    },
  };

  return (
    <>
      <DataBox
        children={[
          <>
            <Field title={t("datasetAddDataGiver")} text={defval?.publisher_name ?? "-"} />
            <Field title={t("datasetAddDataGiverEmail")} text={defval?.publisher_email ?? "-"} />
            <Field title={t("datasetAddDataGiverWebsite")} text={!defval?.publisher_website || defval?.publisher_website === "null" ? "-" : defval?.publisher_website} />
          </>,
          // <>
          //   {/* Datu īpašnieks -> jāpārsauc par datu turētājs, pietiek ar nosaukumu, nevajag e pastu */}
          //   {/* Edit: vajag e-pastu */}

          //   {/* vajag editable */}
          //   {/* <Field title={t("datasetAddDataOwner")} text={defval?.data_owner_name ?? "-"} />
          //   <Field title={t("datasetAddDataOwnerEmail")} text={defval?.data_owner_email ?? "-"} /> */}
          // </>,
        ]}
      />
      <Input
        title={t("datasetAddDataOwner")}
        name={"data_owner_name"}
        placeholder={t("requiredField")}
        defval={defval?.data_owner_name}
        register={register}
        errors={errors}
        rules={{
          required: { message: "fieldIsRequired", value: true },
        }}
        maxLength={250}
        setValue={setValue}
        getValues={getValues}
        getFieldState={getFieldState}
      />
      <Input
        title={t("datasetAddDataOwnerEmail")}
        name={"data_owner_email"}
        placeholder={t("requiredField")}
        defval={defval?.data_owner_email}
        register={register}
        errors={errors}
        rules={emailRules}
        maxLength={250}
        setValue={setValue}
        getValues={getValues}
        getFieldState={getFieldState}
      />
      <DataBox
        children={[
          <div className="input-group">
            <Dropdown
              fieldName="publisher_declaration"
              placeholder={t("datasetAddDeclarationPlaceholder")}
              defval={defval?.publisher_declaration}
              disabled={defval?.data_set_statuss === "publish"}
              register={register}
              errors={errors}
              rules={{
                required: { message: "fieldIsRequired", value: true },
              }}
              options={declarations?.map((item) => ({
                key: item.term_id,
                value: item.term_title,
              }))}
              setValue={setValue}
              getValues={getValues}
              getFieldState={getFieldState}
            />
          </div>,
        ]}
        label={t("datasetAddDeclaration")}
      />
    </>
  );
};

export default DataProviderInfo;
