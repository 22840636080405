import { DataBox, Dropdown, Input, MultipleDropdown } from "..";
import { useTranslation } from "react-i18next";
import Field from "../../../../common/atoms/textfield";
import { useContext, useState } from "react";
import { StateContext } from "../../../../context";
import { formatLatvianDate } from "../../../card/components/dataholder";
import moment from "moment";

const BasicInfo = ({
  defval,
  getValues,
  register,
  getFieldState,
  setValue,
  errors,
  sampleFile,
  setSampleFile,
  setSampleFileChanged,
  selectedSyntax,
  fileErrors,
  setFileErrors,
  languages
}) => {
  const { t } = useTranslation();

  const { language } = useContext(StateContext);

  return (
    <>
      <div className="input-group">
        <Input
          title={t("datasetAddLatvianLanguage")}
          name={"dataset_name_lv"}
          placeholder={t("requiredField")}
          defval={defval?.dataset_name_lv}
          register={register}
          errors={errors}
          rules={{
            required: { message: "fieldIsRequired", value: true },
          }}
          maxLength={250}
          setValue={setValue}
          getValues={getValues}
          getFieldState={getFieldState}
        />
        <Input
          title={t("datasetAddEnglishLanguage")}
          name={"dataset_name"}
          placeholder={t("requiredField")}
          defval={defval?.dataset_name}
          register={register}
          errors={errors}
          rules={{
            required: { message: "fieldIsRequired", value: true },
          }}
          maxLength={250}
          setValue={setValue}
          getValues={getValues}
          getFieldState={getFieldState}
        />
        <Input
          name={"dataset_description_lv"}
          placeholder={t("requiredField")}
          title={t("datasetAddDescriptionLatvian")}
          defval={defval?.dataset_description_lv}
          register={register}
          errors={errors}
          rules={{
            required: { message: "fieldIsRequired", value: true },
          }}
          setValue={setValue}
          getValues={getValues}
          getFieldState={getFieldState}
          big={true}
        />
        <Input
          name={"dataset_description"}
          title={t("datasetAddDescriptionEnglish")}
          placeholder={t("requiredField")}
          defval={defval?.dataset_description}
          register={register}
          errors={errors}
          rules={{
            required: { message: "fieldIsRequired", value: true },
          }}
          setValue={setValue}
          getValues={getValues}
          getFieldState={getFieldState}
          big={true}
        />
      </div>

      {/* still have to decide which to use (disabled inputs or DataBox) */}
      <div className="input-group">
        <Input
          title={t("datasetAddDate")}
          name={"metadata_date"}
          placeholder={t("requiredField")}
          defval={defval?.metadata_date}
          register={register}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          getFieldState={getFieldState}
          disabled={true}
        />
        <Input
          title={t("datasetModificationDate")}
          name={"changed_date"}
          placeholder={"-"}
          // defval={defval?.changed_date}
          defval={formatLatvianDate(moment(defval?.changed_date, "DD.MM.YYYY HH:mm"), true)}
          register={register}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          getFieldState={getFieldState}
          disabled={true}
        />
      </div>
      {/* <DataBox
        children={[
          <>
            <Field title={t("datasetAddDate")} text={defval?.metadata_date ?? "-"} />
            <Field title={t("datasetModificationDate")} text={defval?.changed_date ?? "-"} />
          </>,
        ]}
      /> */}

      <DataBox
        label={t("datasetAddMetadataLanguage")}
        children={[
          <div className="input-group">
            <MultipleDropdown
              disabled={true}
              fieldName="metadata_language"
              placeholder={t("dropdownPick")}
              defval={["lav", "eng"]}
              // defval={defval?.metadata_language ? defval?.metadata_language : language === "lv" ? ["lav"] : ["eng"]}
              // defval={defval?.metadata_language ? defval?.metadata_language : language === "lv" ? "lav" : "eng"} // maybe waiting for edgars answer
              register={register}
              errors={errors} 
              rules={{
                required: { message: "fieldIsRequired", value: true },
              }}
              options={languages}
              setValue={setValue}
              getValues={getValues}
              getFieldState={getFieldState}
            />
          </div>,
        ]}
      />
    </>
  );
};

export default BasicInfo;
