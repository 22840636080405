import React, { useEffect, useState } from "react";
import { DataBox, Dropdown, Input, MultipleDropdown } from "..";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { modelIsDatex } from "./dataformat";

const DataInfo = ({
  trigger,
  defval,
  getValues,
  register,
  getFieldState,
  setValue,
  type,
  isNew,
  errors,
  categories,
  publicationTypes,
  coordinateSystem,
  georeferencingMethod,
  geocoverage_nuts,
  updateFrequency,
  serviceType,
  transportNetworkCoverage,
  typesAccordingToEURegulation,
  esRegulas,
  transportModeCoverage,
  resourceTypes,
  languages,
  interfaces,
  licenceTypes,
  quality_level,
  default_geocoverage_term,
  selectedModel,
  datexModelTerms,
}) => {
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState(
    defval?.parent_taxonomy_terms ? defval?.parent_taxonomy_terms[0]?.tid ?? "" : ""
  );
  const [selectedRegulation, setSelectedRegulation] = useState(
    Array.isArray(defval?.regula) ? defval.regula[0] ?? "" : defval?.regula
  );

  const [selectedGeoreferencingMethod, setSelectedGeoreferencingMethod] = useState(
    defval?.georeferencing_method ? defval.georeferencing_method : ""
  );

  const [resetOptions, setResetOptions] = useState(false);

  useEffect(() => {

    const isModelDatex = modelIsDatex(selectedModel, datexModelTerms);

    if (isModelDatex) {
      setSelectedGeoreferencingMethod("openlr");
    } else {
      setSelectedGeoreferencingMethod("");
    }
  }, [selectedModel, datexModelTerms]);
  
  useEffect(() => {
    // this is used so legal_framework field revalidates after the eu_regulation field changes selectedRegulation state
    trigger("legal_framework");
  }, [selectedRegulation, trigger]);

  return (
    <>
      {/* {!isNew && defval?.data_set_statuss === "publish" && (
        <>
          <DataBox
            children={[
              <Link className="box-url" target={"_blank"} to={"/card/" + defval?.uuid}>
                {t("acessLink")}
              </Link>,
            ]}
          />
        </>
      )} */}

      <DataBox
        label={t("datasetAddCategory")}
        children={[
          <div className="input-group">
            <Dropdown
              fieldName="content_category"
              placeholder={t("datasetAddCategory")}
              defval={selectedCategory}
              register={register}
              errors={errors}
              rules={{
                required: { message: "fieldIsRequired", value: true },
              }}
              options={categories.map((category) => ({
                key: category.key.toString(),
                value: category.value,
              }))}
              onChange={(val) => {
                setSelectedCategory(val);
                setResetOptions((current) => !current);
              }}
              setValue={setValue}
              getValues={getValues}
              getFieldState={getFieldState}
            />

            <MultipleDropdown
              fieldName="content_category_detailed"
              placeholder={t("datasetAddSubCategory")}
              disabled={getValues("content_category") === ""}
              defval={
                defval?.child_taxonomy_terms
                  ? Array.isArray(defval?.child_taxonomy_terms)
                    ? defval?.child_taxonomy_terms?.map((term) => term.tid)
                    : [defval?.child_taxonomy_terms]
                  : []
              }
              register={register}
              errors={errors}
              rules={{
                required: { message: "fieldIsRequired", value: true },
              }}
              options={
                categories
                  .find((cat) => cat.key === Number(selectedCategory))
                  ?.children.map((child) => ({ key: child.key, value: child.value })) ?? []
              }
              setValue={setValue}
              resetOptions={resetOptions}
              getValues={getValues}
              getFieldState={getFieldState}
            />
          </div>,
        ]}
      />
      <div className="input-group">
        <Dropdown
          title={t("datasetAddDataTypeAccordingToEURegulation")}
          fieldName="data_type_eu_reg"
          placeholder={t("dropdownPick")}
          defval={defval?.data_type_eu_reg}
          register={register}
          errors={errors}
          // rules={{
          //   required: { message: "fieldIsRequired", value: true },
          // }}

          onChange={(val) => {
            const selectedRegulaTID = typesAccordingToEURegulation?.find((item) => item.key === val)?.linkedRegulation;
            console.log("selectedRegulaTID", selectedRegulaTID);
            setSelectedRegulation(selectedRegulaTID);
          }}

          options={typesAccordingToEURegulation}
          getValues={getValues}
          setValue={setValue}
          getFieldState={getFieldState}
        />
        <Dropdown
          title={t("datasetAddDataLegalFramework")}
          fieldName="legal_framework"
          placeholder={t("willBeFilledAutomatically")}
          tooltipText={t("datasetAddDataLegalFrameworkTooltip")}
          defval={selectedRegulation}
          optionalValueState={selectedRegulation}
          valueCanBeChangedExternally={true}
          options={esRegulas}
          register={register}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          getFieldState={getFieldState}
          disabled={true}
        />
        {/* MTTIS servisa veids here */}
        <div className="input-group">
          <Dropdown
            fieldName="service_type"
            title={t("datasetServiceType")}
            placeholder={t("dropdownPick")}
            defval={defval?.service_type}
            register={register}
            errors={errors}
            options={serviceType}
            // rules={{
            //   required: { message: "fieldIsRequired", value: true },
            // }}
            // disabled={true}
            setValue={setValue}
            getValues={getValues}
            getFieldState={getFieldState}
          />
        </div>
        <Input
          isDate
          title={t("validFrom")}
          name="valid_from"
          placeholder={t("validFrom")}
          defval={defval?.valid_from}
          register={register}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          getFieldState={getFieldState}
          rules={{
            required: { message: "fieldIsRequired", value: true },
          }}
          minDate={
            moment(defval?.valid_from, "DD.MM.YYYY HH:mm", true).isValid()
              ? moment.min(
                  moment(defval?.valid_from, "DD.MM.YYYY HH:mm").subtract(1, 'days'),
                  moment().subtract(1, 'days')
                ).format("DD.MM.YYYY HH:mm")
              : moment().subtract(1, 'days').format("DD.MM.YYYY HH:mm")
          }
          // disabled={true}
        />
        <Input
          isDate
          minDate={defval?.valid_from}
          title={t("validUntil")}
          name="valid_to"
          placeholder={t("nonRequiredField")}
          defval={defval.valid_to}
          register={register}
          errors={errors}
          trigger={trigger}
          rules={{
            validate: {
              isAfterValidFrom: (value) => {
                // field can be empty
                if (!value) {
                  return true;
                }

                const dateFormatRegex = /^\d{2}\.\d{2}\.\d{4} \d{2}:\d{2}$/;
                const validFrom = getValues("valid_from");

                const dateObj = moment(value, "DD.MM.YYYY HH:mm");

                // if dateObj is NOT valid date
                if (!dateObj.isValid()) {
                  return t("datasetErrorInvalidDate");
                }
                // if dateObj is before validFrom
                if (validFrom && dateObj.isBefore(moment(validFrom, "DD.MM.YYYY HH:mm"))) {
                  return t("validUntilShouldBeAfterValidFrom");
                }

                // if value is not in correct format
                if (!dateFormatRegex.test(value)) {
                  return t("datasetErrorInvalidDate");
                }

                return true;
              },
            },
          }}
          tooltipText={t("datasetAddValidToTooltip")}
          setValue={setValue}
          getValues={getValues}
          getFieldState={getFieldState}
        />
        <Dropdown
          title={t("datasetAddUpdateFreq")}
          fieldName="update_frequency"
          placeholder={t("dropdownPick")}
          defval={defval?.update_frequency}
          register={register}
          errors={errors}
          rules={{
            required: { message: "fieldIsRequired", value: true },
          }}
          options={updateFrequency}
          getValues={getValues}
          setValue={setValue}
          getFieldState={getFieldState}
        />
      </div>
      <DataBox
        label={t("datasetAddContentLanguage")}
        children={[
          <div className="input-group">
            <MultipleDropdown
              fieldName="language_content"
              placeholder={t("dropdownPick")}
              defval={defval?.language_content}
              register={register}
              errors={errors}
              rules={{
                required: { message: "fieldIsRequired", value: true },
              }}
              options={languages}
              setValue={setValue}
              getValues={getValues}
              getFieldState={getFieldState}
            />
          </div>,
        ]}
      />

      <div className="input-group">

      <Input
        name="data_version"
        placeholder={"1.0"}
        title={t("datasetAddUpdated")}
        disabled={true}
        register={register}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        getFieldState={getFieldState}
      />

      <Input
        name="date_version_notes"
        title={t("datasetChangeNotes")}
        disabled={true}
        placeholder={"-"}
        register={register}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        getFieldState={getFieldState}
      />

      </div>

      {default_geocoverage_term ? (
        <DataBox
          label={t("datasetAddGeographicalCoverage")}
          children={[
            <div className="input-group">
              <Dropdown
                disabled
                fieldName="geographic_coverage_country"
                register={register}
                errors={errors}
                rules={{
                  required: { message: "fieldIsRequired", value: true },
                }}
                options={geocoverage_nuts}
                defval={default_geocoverage_term?.key}
                setValue={setValue}
                getValues={getValues}
              />
              <MultipleDropdown
                fieldName="geographic_coverage_nuts"
                placeholder={t("datasetAddGeographicalCoverageRegion")}
                defval={defval?.geographic_coverage_nuts?.filter(
                  (item) => item !== default_geocoverage_term?.key
                )}
                register={register}
                rules={{
                  required: { message: "fieldIsRequired", value: true },
                }}
                errors={errors}
                options={geocoverage_nuts.filter((item) => item.key !== default_geocoverage_term?.key)}
                setValue={setValue}
                getValues={getValues}
                getFieldState={getFieldState}
                tooltipText={t("datasetAddGeographicalCoverageTooltip")}
              />
            </div>,
          ]}
        />
      ) : (
        <></>
      )}

      <div className="input-group">
        <Dropdown
          title={t("datasetCoordinateSystem")}
          fieldName="coordinate_system"
          placeholder={t("dropdownPick")}
          defval={defval?.coordinate_system}
          register={register}
          errors={errors}
          rules={{
            required: { message: "fieldIsRequired", value: true },
          }}
          options={coordinateSystem}
          setValue={setValue}
          getValues={getValues}
          getFieldState={getFieldState}
        />
        <Dropdown
          title={t("datasetGeoreferencing") + ":"}
          fieldName="georeferencing_method"
          placeholder={t("willBeFilledAutomatically")}
          defval={selectedGeoreferencingMethod}
          optionalValueState={selectedGeoreferencingMethod}
          valueCanBeChangedExternally={true}
          register={register}
          errors={errors}
          options={georeferencingMethod}
          setValue={setValue}
          getValues={getValues}
          getFieldState={getFieldState}
          disabled={true}
        />
        <Dropdown
          title={t("datasetAddTransportNetworkCoverage")}
          fieldName="transport_network_coverage"
          placeholder={t("dropdownPick")}
          defval={defval?.transport_network_coverage}
          register={register}
          errors={errors}
          // rules={{
          //   required: { message: "fieldIsRequired", value: true },
          // }}
          options={transportNetworkCoverage}
          setValue={setValue}
          getValues={getValues}
          getFieldState={getFieldState}
        />
        <Dropdown
          title={t("datasetAddTransportModeCoverage")}
          fieldName="transport_mode_coverage"
          placeholder={t("dropdownPick")}
          defval={defval?.transport_mode_coverage}
          register={register}
          errors={errors}
          rules={{
            required: { message: "fieldIsRequired", value: true },
          }}
          options={transportModeCoverage}
          setValue={setValue}
          getValues={getValues}
          getFieldState={getFieldState}
        />
        <Dropdown
          title={t("datasetAddQualityDescription")}
          fieldName="quality_level"
          placeholder={t("dropdownPick")}
          defval={defval?.quality_level}
          register={register}
          errors={errors}
          rules={{
            required: { message: "fieldIsRequired", value: true },
          }}
          options={quality_level}
          setValue={setValue}
          getValues={getValues}
          getFieldState={getFieldState}
        />
        {isNew && (
          <Dropdown
            title={t("usageConditions")}
            fieldName="usage_conditions"
            placeholder={t("dropdownPick")}
            defval="free_open_data"
            register={register}
            errors={errors}
            disabled={true}
            options={[{ key: "free_open_data", value: "License, free use/open data" }]}
            setValue={setValue}
            getValues={getValues}
            getFieldState={getFieldState}
          />
        )}
        <Dropdown
          title={t("dataseteAddStandardLicence")}
          fieldName="licence_conditions"
          placeholder={t("dropdownPick")}
          defval={defval?.licence_conditions}
          register={register}
          errors={errors}
          rules={{
            required: { message: "fieldIsRequired", value: true },
          }}
          options={licenceTypes}
          setValue={setValue}
          getValues={getValues}
          getFieldState={getFieldState}
        />
      </div>
    </>
  );
};

export default DataInfo;
