import { useContext, useEffect } from "react";
import { StateContext } from "../context";
import { useNavigate } from "react-router-dom";

const RedirectToLv = () => {
  const { language } = useContext(StateContext);
  // language is taken from localStorage, so if user has visited the site before, language will be taken from there
  // otherwise its deafult /lv
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const redirectTo = params.get("redirectTo");

  useEffect(() => {
    navigate(`/${language}${redirectTo ? `?redirectTo=${redirectTo}` : ""}`);
  }, [language, navigate, redirectTo]);
};

export default RedirectToLv;
