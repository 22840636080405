import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import ReportModal from "../../../modals/reportmodal";
import RateModal from "../../../modals/ratemodal";
import BasicInfoTable from "./basicinfotable";
import PublicInfo from "./publicinfo";
import { auth, fetch } from "../../../classes/auth";
import { StateContext } from "../../../context";
import { postCms } from "../../../classes/cmsApi";
import { Trans, useTranslation } from "react-i18next";
import { usr } from "../../../classes/user";
import ModalComponent from "../../../modals/modal";
import response_key_map from "../../../../responseKeyMap";
import { NotificationBanner } from "../../../common";
import { t } from "i18next";
import { formatLatvianDate } from "./dataholder";

import { DEFAULT_MAP_URL } from "../../../common/footer";
import SmoothScrollLink from "../../../components/SmoothScrollLink";

const DataDownload = ({
  files,
  publicFiles,
  nid,
  setErrorKey,
  dkName,
  lastChanged,
  sample = false,
  onClick = undefined,
  downloadable = true,
}) => {
  const { t } = useTranslation();
  const downloadBanner = {
    messageKey: "",
    ok: true,
    key: 1,
  };
  const { handleModalOpen, language } = useContext(StateContext);
  const [downloadingFiles, setDownloadingFiles] = useState({});

  if (!files?.length) return null;

  const downloadFile = async (file_id, file_name, file_format, file_url = null) => {
    try {
      if (!downloadable) {
        return;
      }
      if (!!onClick) {
        onClick();
        return;
      }

      setDownloadingFiles((prev) => ({ ...prev, [file_id]: true }));

      let response;
      if (file_url) {
        response = await fetch(file_url, {
          method: `GET`,
          headers: {
            crossDomain: true,
            Accept: `application/json`,
          },
        });
        if (!response.ok) {
          console.error(response);
          return;
        }
        response = await response.blob();
      } else {
        try {
          response = await postCms("api/v1/get/file/download-file", { file_id, nid, format: file_format }, null, {}, true);
        } catch (err) {
          console.log("Error downloading file", err);
          if (err.status === 204) {
            setErrorKey("no_files_found");
            handleModalOpen("error_modal");
          }
          return;
        }
      }
      const url = window.URL.createObjectURL(response);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${file_name}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      setErrorKey(err?.error?.response_key);
      handleModalOpen("error_modal");
    } finally {
      setDownloadingFiles((prev) => ({ ...prev, [file_id]: false }));
    }
  };

  const formatFileDate = (date, file_id) => {
    if (file_id === 0) {
      return formatLatvianDate(lastChanged);
    }
    if (file_id === 1) {
      return formatLatvianDate(lastChanged);
    }
    return formatLatvianDate(date, true);
  };

  const formatFileName = (name, file_format, file_id, from_sipr) => {
    // if sample
    if (file_id === 0 || sample) {
      return `NPP_DK_${t("dataSample")}_${nid}.${file_format}`;
    }

    if (from_sipr) {
      return `NPP_DK_${nid}.${file_format}`;
    }
    return name;
  };

  const formatFileDescription = (description, file_id, file_format, from_sipr) => {
    // if sample
    if (file_id === 0 || sample) {
      return t("dataSampleFull");
    }
    // if dataset dataset description
    if (file_id === -1) {
      return t("datasetDescription");
    }

    if (from_sipr) {
      if (file_format.toLowerCase().includes("xml")) {
        return t("dataSetXML");
      }
      if (file_format.toLowerCase().includes("geojson")) {
        return t("dataSetGeoJSON");
      }
      return t("dataSet");
    }
    return description;
  };

  const addBreakPoints = (text, breakPoints) => {
    const processedText = breakPoints.reduce(
      (acc, breakPoint) => acc.replace(new RegExp(`\\${breakPoint}`, 'g'), `${breakPoint}\u200B`),
      text
    );
    return processedText;
  };
  return (
    <>
      {downloadBanner.messageKey && (
        <NotificationBanner
          text={t(downloadBanner.messageKey)}
          backgroundColor={downloadBanner.ok ? "#33D751" : "#9e313a"}
          canClose={true}
          key={downloadBanner.key}
        />
      )}
      <div className="card-content-detail-tab-tabpane-content-download">
        {files?.map((file) => {
          const { file_id, from_sipr, file_title, file_title_en, file_name, file_url, file_datetime } = file;


          // console.log("File: ", file);

          const url = file_name;
          const file_format = url.substring(url.lastIndexOf(".") + 1);
          const translatedTitle = language === "lv" ? file_title : file_title_en;

          const display_name = formatFileName(file_name, file_format, file_id, from_sipr);
          const display_description = formatFileDescription(translatedTitle, file_id, file_format, from_sipr);
          const display_datetime = formatFileDate(file_datetime, file_id);

          // file name - name of uploaded file
          // file title - description the data provider has given to the file
          // file datetime - date and time of the file upload
          return (
            <div key={file_id}
              className="card-content-detail-tab-tabpane-content-download-item"
            >
              <div className="card-content-detail-tab-tabpane-content-download-item-icon">
              {downloadingFiles[file_id] ? (
                <div
                  className="loader"
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                ></div>
              ) : (
                <img
                  alt="alt"
                  src="/assets/img/download.svg"
                  className={`${downloadable ? "" : "gray-out"}`}
                  style={{
                    width: "20px",
                    height: "20px",
                    cursor: `${downloadable ? "pointer" : "default"}`,
                  }}
                />
              )}
              </div>
              <div
                className={`focus-red card-content-detail-tab-tabpane-content-download-item-filename${
                  downloadable && !downloadingFiles[file_id] ? "" : " gray-out"
                }`}
                onClick={async () => {
                  if (!auth.isAuthorized() && !publicFiles) return handleModalOpen("auth");
                  if (downloadingFiles[file_id]) return;
                  await downloadFile(file_id, display_name, file_format, file_url);
                }}
                tabIndex={downloadable && !downloadingFiles[file_id] ? 0 : -1}
                onKeyDown={async (event) => {
                  if (event.key === "Enter") {
                    if (!auth.isAuthorized() && !publicFiles) return handleModalOpen("auth");
                    if (downloadingFiles[file_id]) return;
                    await downloadFile(file_id, display_name, file_format, file_url);
                  }
                }}
              >
                {addBreakPoints(display_name, ["_", "."])}
              </div>
              <div className="card-content-detail-tab-tabpane-content-download-item-filetitle">
                {addBreakPoints(display_description, ["_", ".", "-"])}
              </div>
              <div className="card-content-detail-tab-tabpane-content-download-item-date">
                {display_datetime}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const decodeUrl = (url) => {
  let correctedUrl = url.replace(/&amp;/g, "&");
  const decodedUrl = decodeURIComponent(correctedUrl);
  return decodedUrl;
};

const InfoBox = ({ data, apiKey, isSubbed }) => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [files, setFiles] = useState([]);
  const [openTab, setOpenTab] = useState(null);
  const [errorKey, setErrorKey] = useState("");
  const { language } = useContext(StateContext);
  const sampleData = useMemo(
    () =>
      data?.field_field_data_sample && [
        {
          file_id: 0,
          file_name: `SampleData.${data?.field_field_data_sample.split(".").pop()}`,
          file_title: `SampleData.${data?.field_field_data_sample.split(".").pop()}`,
          file_url: data?.field_field_data_sample,
        },
      ],
    [data?.field_field_data_sample]
  );

  // useEffect(() => {
  //   if (data) {
  //     Object.keys(data).forEach((key) => {
  //       console.log(`${key}: ${data[key]}`);
  //     });
  //   }
  // }, [data]);

  const formatDatetimeForDescription = (datetime) => {
    if (!datetime) return "";
    
    if (typeof datetime !== "string") return ""; 

    // assuming the format is <time datetime="2025-01-07T11:21:02Z" class="datetime">2025-01-07T13:21:02+0200</time>
    const date = datetime.split(">")[1]?.split("<")[0];
    if (!date) return "";

    const dateObj = new Date(date);

    return dateObj
  };


  const descriptionFileData = useMemo(() => {
    if (!data) return null;


  
    // Get file details based on current language
    let fileUrl = language === "lv" ? data.field_dataset_desc_file_lv : data.field_dataset_desc_file;
    let date = language === "lv" ? data.field_desc_file_lv_datetime : data.field_desc_file_datetime;
  
    // If file details are missing in the current language, use the alternative
    if (!fileUrl) {
      fileUrl = language === "lv" ? data.field_dataset_desc_file : data.field_dataset_desc_file_lv;
      date = language === "lv" ? data.field_desc_file_datetime : data.field_desc_file_lv_datetime;
    }

    const fileName = `NPP_DK_${language === "lv" ? "Apraksts" : "Description"}_${data.nid}.${fileUrl.split(".").pop()}`
  
    // Ensure the URL is valid
    if (!fileUrl || !fileUrl.split("/").pop()) return null;

    return [{
      file_id: -1,
      file_name: fileName,
      file_url: fileUrl,
      file_datetime: formatDatetimeForDescription(date),
    }];
  }, [data, language]);
  
  const handleFiles = useCallback(() => {
    if (!data?.title) return;
    if (data?.field_sipr_dataset_uid === "1") {
      return setFiles([
        ...(data?.field_field_data_format_syntax.includes("XML")
          ? [
              {
                file_id: 1,
                file_name: "Dataset.xml",
                file_title: "Dataset.xml",
                from_sipr: true,
              },
            ]
          : []),
        ...(data?.field_field_data_format_syntax.includes("GeoJSON")
          ? [
              {
                file_id: 2,
                file_name: "Dataset.geojson",
                file_title: "Dataset.geojson",
                file_datetime: data.field_changed_date,
                from_sipr: true,
                format: "geojson",
                contentType: "json",
              },
            ]
          : []),
      ]);
    }

    // parse drupal text field to json
    let parser = new DOMParser();
    let doc = parser.parseFromString(data.title, "text/html");

    let decodedString = doc.documentElement.textContent;
    let jsonData = JSON.parse(decodedString);
    setFiles(jsonData);
  }, [data]);

  useEffect(() => {
    handleFiles();
  }, [handleFiles]);

  const calculateMaxLineWidth = (element) => {
    const computedStyle = window.getComputedStyle(element);
    const fontSize = parseFloat(computedStyle.fontSize);
    // console.log("Font size: ", fontSize);
    const fontFamily = computedStyle.fontFamily;
    const fontWeight = computedStyle.fontWeight;

    // console.log("Element: ", element);

    // console.log("Font family: ", fontFamily);
    // console.log("Font weight: ", fontWeight);


    const measureTextWidth = (text) => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      context.font = `${fontWeight} ${fontSize}px ${fontFamily}`;
      context.margin = 0;
      context.padding = 0;
      context.border = 0;
      const textWidth = context.measureText(text).width;

      // console.log("Text: ", text, "Width: ", textWidth);
      // cleanup
      canvas.remove();
      return textWidth;
    };

    const range = document.createRange();
    const lines = [];
    const words = element.textContent.split(/[\s\u00A0\u200B]+/);

    // console.log("Words: ", words);

    let currentLine = "";

    words.forEach((word, wordIndex) => {
      if (!word || word === "") {
        return;
      }

      // console.log("Word: ", word);
      // console.log("Word divider character: ", word[word.length - 1]);

      const lastCharacter = word[word.length - 1];

      const shouldUseZeroWidthSpace = lastCharacter === "-" || lastCharacter === "." || lastCharacter === "_";

      const testLine = currentLine ? `${currentLine}${shouldUseZeroWidthSpace ? "" : " "}${word}` : word;

      range.selectNodeContents(element);
      range.setStart(element.firstChild, 0);
      range.setEnd(element.firstChild, testLine.length);

      const width = measureTextWidth(testLine);
      if (width > element.clientWidth) {
        lines.push(currentLine.trim());
        currentLine = word;
      } else {
        currentLine = testLine;
      }
      if (wordIndex === words.length - 1) {
        lines.push(currentLine.trim());
      }
    });

    let maxLineWidth = 0;

    lines.forEach((line, index) => {
      const lineWidth = measureTextWidth(line);
      const remainingSpace = element.clientWidth - lineWidth;

      if (lineWidth > maxLineWidth) {
        maxLineWidth = lineWidth;
      }

      if (remainingSpace < 0) {
        maxLineWidth = element.clientWidth;
      }

      // console.log(`  Line ${index + 1}:`);
      // console.log(`  Text: "${line}"`);
      // console.log(`  Line Width: ${lineWidth.toFixed(2)}px`);
      // console.log(`  Remaining Space: ${remainingSpace.toFixed(2)}px`);
      // console.log("  ------------");
    });
    return maxLineWidth;
  };

  const setWidthToLongestline = useCallback((element, elementIndex) => {
    // console.log(`Analyzing element ${elementIndex + 1}:`);

    // console.log("max allowed width: ", element.clientWidth);
     if (!element || !element.textContent || element.textContent.length === 0) {
        console.warn("Element is empty or does not exist.");
        return;
      }

      const maxLineWidth = calculateMaxLineWidth(element);

      // console.log(`  Max Line Width: ${maxLineWidth}px`);
      // set the width of the file title to the current widht - the smallest remaining space
      element.style.width = `${Math.ceil(parseFloat(maxLineWidth) + 1)}px`;
      element.style.minWidth = `${Math.ceil(parseFloat(maxLineWidth) + 1)}px`;
      element.style.maxWidth = `${Math.ceil(parseFloat(maxLineWidth) + 1)}px`;
      // console.log("Set element width to: ", element.style.width);

      const sibling = element.parentElement.querySelector('.card-content-detail-tab-tabpane-content-download-item-filetitle');

      if (sibling) {

        const parentWidth = element.parentElement.clientWidth;
        const dateWidth = sibling.parentElement.querySelector('.card-content-detail-tab-tabpane-content-download-item-date').clientWidth;
        // console.log("Date width: ", dateWidth);
        const remainingSpace = Math.ceil(parseFloat(parentWidth) - parseFloat(maxLineWidth) - parseFloat(dateWidth) - 130);
        // console.log("Parent width: ", parentWidth, "remainingSpace: ", remainingSpace);
        // console.log("Sibling width: ", sibling.clientWidth);
        // sibling.style.maxWidth = `${remainingSpace}px`;
        // sibling.style.minWidth = `${remainingSpace}px`;
        // sibling.style.width = `${remainingSpace}px`;
        // console.log("Sibling width after setting: ", sibling.clientWidth);

        // create new element that is on the width of remaining width to calculate lines

        // console.log("sibling font size: ", window.getComputedStyle(sibling).fontSize);

        const tempElement2 = document.createElement('div');
        tempElement2.id = `tempElement${elementIndex}`;
        tempElement2.style.position = 'absolute';
        tempElement2.style.fontSize = window.getComputedStyle(sibling).fontSize;
        tempElement2.style.fontFamily = window.getComputedStyle(sibling).fontFamily;
        tempElement2.style.width = `${remainingSpace}px`;
        tempElement2.style.maxWidth = `${remainingSpace}px`;
        tempElement2.style.minWidth = `${remainingSpace}px`;
        tempElement2.style.visibility = 'hidden';
        tempElement2.style.whiteSpace = 'nowrap';
        tempElement2.style.wordWrap = 'break-word';
        tempElement2.style.boxSizing = 'border-box';
        tempElement2.style.padding = '0';
        tempElement2.style.margin = '0';
        tempElement2.style.border = '0';
        tempElement2.style.overflow = 'hidden';
        tempElement2.style.top = '-9999px'; // Position it off-screen
        tempElement2.textContent = sibling.textContent

        if (!document.body.contains(tempElement2)) {
            document.body.appendChild(tempElement2);
        }

        // console.log("Created temp element with width: ", tempElement2.clientWidth);

        const tempElement = document.getElementById(`tempElement${elementIndex}`);

        const siblingMaxLineWidth = calculateMaxLineWidth(tempElement);

        // cleanup
        tempElement.remove();

        // console.log("Sibling max line width: ", siblingMaxLineWidth);
        
        sibling.style.maxWidth = `${Math.ceil(parseFloat(siblingMaxLineWidth) + 1)}px`;
        sibling.style.width = `${Math.ceil(parseFloat(siblingMaxLineWidth) + 1)}px`;
        sibling.style.minWidth = `${Math.ceil(parseFloat(siblingMaxLineWidth) + 1)}px`;
        // get siblings longest line width after it being max of previously calculated maxWidth

        // console.log(`Set sibling element width to: calc(${remainingSpace}px)`);
      } else {
        console.warn("No sibling element found with the specified class.");
      }
  }, []);

  useEffect(() => {

    // console.log("Window width: ", windowWidth);

    if ( windowWidth < 1280) {
      return;
    }

    const targetElements = document.querySelectorAll(
      ".card-content-detail-tab-tabpane-content-download-item-filename"
    );
  
    if (targetElements.length > 0) {
      targetElements.forEach((element, elementIndex) => {
        setWidthToLongestline(element, elementIndex);
      });
    } else {
      console.warn("No elements found with the specified selector.");
    }
  }, [files, windowWidth, language, setWidthToLongestline]);

  const handleSizeChange = () => {
    setWindowWidth(window.innerWidth);
  };


  useEffect(() => {
    window.addEventListener("resize", handleSizeChange, false);

    return () => {
      window.removeEventListener("resize", handleSizeChange, false);
    }
  }, []);


  if (!Object.keys(data).length) return null;

  const handleMapOpen = () => {
    if (language === "lv")
      window.open(
        data.field_link_to_map ? decodeUrl(data.field_link_to_map) : DEFAULT_MAP_URL + `${language}`
      );
    else
      window.open(
        data.field_link_to_map_en ? decodeUrl(data.field_link_to_map_en) : DEFAULT_MAP_URL + `${language}`
      );
  };
  

  return (
    <div className="card-content-detail">
      {
        <ModalComponent
          id="error_modal"
          trigger={<div style={{ marginTop: "60px" }}></div>}
          small
          title={response_key_map[errorKey]?.title || t("errorOccured")}
          className={"modal-stack rate-modal"}
        >
          {response_key_map[errorKey]?.body || t("errorOccuredMessage")}
        </ModalComponent>
      }
      <div className="card-content-detail-tab">
        <div className="card-content-detail-tab-tabbutton">
          <button className="card-content-detail-tab-tabbutton-tablink active" tabIndex={-1}>
            <Trans i18nKey="datasetDescription" />
          </button>
          {(language === "lv" ? data.field_link_to_map : data.field_link_to_map_en) && (
            <button
              onClick={() => handleMapOpen()}
              className="card-content-detail-tab-tabbutton-tablink"
              tabIndex={-1}
            >
              <span
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handleMapOpen();
                  }
                }}
              >
                <img alt="alt" src="/assets/img/tab-icon.png" />
                <Trans i18nKey="datasetViewMap"></Trans>
              </span>
            </button>
            )}
        </div>
      </div>
      <div>
        <div className="card-content-detail-tab-tabpane-header">
          <p className="card-content-detail-tab-tabpane-header-title">
            <span className="card-content-detail-tab-tabpane-content-value">
              {language === "lv" ? data.field_dataset_name_lv : data.field_dataset_name}
            </span>
          </p>
          <br />
          <p className="card-content-detail-tab-tabpane-header-description">
            {language === "lv" ? data.field_dataset_description_lv : data.field_dataset_description}
          </p>
        </div>
        <img
          alt="alt"
          src="/assets/img/npp-datukopa-standarta-reklamkarogs-3.png"
          style={{ width: "100%" }}
        />
        <div className="card-content-detail-tab-tabpane-content">
          <span>
            {data.field_format_model && (
              <>
                <Trans i18nKey="datasetModel" />
                <span className="card-content-detail-tab-tabpane-content-value">
                  {/* {data.field_format_model} */}
                  {/* {data.field_format_model} */}
                  {data.field_format_model_parent ? data.field_format_model_parent : data.field_format_model ?? ""}
                </span>
              </>
            )}
          </span>
          {data.field_language_content && data.field_language_content !== "null" && (
            <span>
              <Trans i18nKey="datasetLanguage" />
              <span className="card-content-detail-tab-tabpane-content-value">
                {data.field_language_content
                  .split(", ")
                  .map((lang) => t(`language${lang}`))
                  .join(", ")}
              </span>
            </span>
          )}
          <span>
            <Trans i18nKey="datasetLegalFramework" />
            <span className="card-content-detail-tab-tabpane-content-value">
              {data.field_regula}
            </span>
          </span>
          <br />
          <span>
            <Trans i18nKey="datasetDistibutionMode" />
            <span className="card-content-detail-tab-tabpane-content-value">
              {data?.field_distribution_mode ?? ""}
            </span>
          </span>
          <br />
          <div className="card-content-detail-tab-tabpane-content-format">
            <div className="card-content-detail-tab-tabpane-content-format-label">
              <Trans i18nKey="datasetAvailableFormats" />
            </div>
            {data?.field_field_data_format_syntax
              .split(",")
              .map((syntax) => syntax.trim())
              .map((syntax) => (
                <span className="card-content-detail-tab-tabpane-content-format-tag">{syntax}</span>
              ))}
          </div>
          <div className="card-content-detail-tab-tabpane-content-download-container">

            {descriptionFileData && (
              <DataDownload
                dkName={language === "lv" ? data.field_dataset_name_lv : data.field_dataset_name}
                files={descriptionFileData}
                nid={data.nid}
                setErrorKey={setErrorKey}
                lastChanged={data.field_changed_date}
                downloadable={true}
              />
            )}

            {sampleData && (
              <DataDownload
                dkName={language === "lv" ? data.field_dataset_name_lv : data.field_dataset_name}
                files={sampleData}
                nid={data.nid}
                setErrorKey={setErrorKey}
                publicFiles
                lastChanged={data.field_data_sample_datetime}
                sample={true}
                downloadable={true}
              />
            )}
            <DataDownload
              dkName={language === "lv" ? data.field_dataset_name_lv : data.field_dataset_name}
              files={files}
              nid={data.nid}
              setErrorKey={setErrorKey}
              lastChanged={data.field_changed_date}
              downloadable={usr?.can("API dokumentācija (datu devējam)") ? false : true}
            />
            {/* static link for all */}
            <DataDownload
              dkName={language === "lv" ? data.field_dataset_name_lv : data.field_dataset_name}
              files={[
                {
                  file_id: "0",
                  file_name: `NPP_metadata_${data.nid}`,
                  file_title: `mobilityDCAT-AP metadati`,
                  file_title_en: `mobilityDCAT-AP metadata`,
                  file_datetime: formatDatetimeForDescription(data.created),
                },
              ]}
              publicFiles
              nid={data.nid}
              setErrorKey={setErrorKey}
              lastChanged={formatDatetimeForDescription(data.created)}
              onClick={() => {
                window.open(
                  `${window.location.origin}/api/v1/subscriber/metadata_dcat/${data?.nid}`,
                  "_blank"
                );
              }}
              downloadable={true}
            />
          </div>
          {/* cant scroll to each individual dropdown, because href happens before react rerenders open tab */}
          <div className="card-content-detail-tab-tabpane-content-accordion" id="info">
            <SmoothScrollLink
              className="card-content-detail-tab-tabpane-content-accordion-title menu-item active"
              onClick={() => {
                if (openTab === 2) {
                  setOpenTab(null);
                } else setOpenTab(2);
              }}
              tabIndex={0}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  setOpenTab(current => current === 2 ? null : 2);
                }
              }}
              style={{ textDecoration: "none" }}
              href="#info"
              preventScroll={openTab === 2}
            >
              <img 
                alt="alt"
                className={`accordion-open-icon-plus ${openTab === 2 ? "open-information" : ""}`}
                src={`/assets/img/${openTab === 2 ? "minus" : "plus"}.svg`}
              />
              <span>
                <Trans i18nKey="basicInfo" />
              </span>
            </SmoothScrollLink>
            <div
              className={`card-content-detail-tab-tabpane-content${
                openTab !== 2 && "-accordion-description"
              }`}
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <PublicInfo data={data} />
            </div>
            <SmoothScrollLink
              className="card-content-detail-tab-tabpane-content-accordion-title menu-item active"
              id="additional-info"
              tabIndex={0}
              onClick={() => {
                if (openTab === 1) {
                  setOpenTab(null);
                } else setOpenTab(1);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  setOpenTab(current => current === 1 ? null : 1);
                }
              }}
              style={{ textDecoration: "none" }}
              href="#info"
              preventScroll={openTab === 1}
            >
              <img
                alt="alt"
                className={`accordion-open-icon-plus ${openTab === 1 ? "open-information" : ""}`}
                src={`/assets/img/${openTab === 1 ? "minus" : "plus"}.svg`}
              />
              <span>
                <Trans i18nKey="additionalInfo" />
              </span>
            </SmoothScrollLink>

            <div
              className={`card-content-detail-tab-tabpane-content${
                openTab !== 1 && "-accordion-description"
              }`}
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <BasicInfoTable data={{ ...data, datasetAddGeographicalCoverageCountry: "latvia" }} openTab={openTab} />
            </div>
          </div>
          {auth.isAuthorized() && usr.can("API dokumentācija (datu ņēmējam)") && isSubbed && (
            <>
              <ReportModal
                trigger={
                  <div className="card-content-detail-tab-tabpane-content-more" style={{ marginTop: "25px" }}>
                    <span className="card-content-detail-tab-tabpane-content-more-text" tabIndex={0}>
                      <Trans i18nKey="datasetReportProblem" />
                    </span>
                    <div className="test"></div>
                  </div>
                }
                nid={data?.nid}
              />
              <RateModal
                trigger={
                  <div className="card-content-detail-tab-tabpane-content-more" style={{ marginTop: "10px" }}>
                    <span className="card-content-detail-tab-tabpane-content-more-text" tabIndex={0}>
                      <Trans i18nKey="popupMessageRate" />
                    </span>
                    <div className="test"></div>
                  </div>
                }
                nid={data?.nid}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default InfoBox;
