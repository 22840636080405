import React, { useContext, useState } from "react";
import { StateContext } from "../context";
import ModalComponent from "./modal";
import { useTranslation } from "react-i18next";
import { postCms } from "../classes/cmsApi";
import { sanitize } from "dompurify";
import PrimaryButton from "../common/atoms/primarybutton";

const IesutneModal = ({ trigger, data, refetch, renderSenderName }) => {
  const { t } = useTranslation();

  const [answerText, setAnswerText] = useState(data?.field_answer || "");
  const [answerStatus, setAnswerStatus] = useState(data?.field_substatus || "answered");

  const transformMessage = (msg) => {
    if (!msg) return "";
    const hasATag = msg.includes("&lt;a");
    if (hasATag) {
      msg = msg.replace(/&quot;/g, '"')
             .replace(/&lt;/g, '<')
             .replace(/&gt;/g, '>');
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      const aTagRegex = /<a.*?>(.*?)<\/a>/g;
      const transformedParts = msg.split(urlRegex).map(part => {
        if (part.match(aTagRegex)) {
          return part;
        }
        return part;
      });   
      return transformedParts.join("");
    }
    msg = msg.replace(/&amp;quot;/g, '"');
    const parts = msg.split(/(\/[a-zA-Z0-9\/\-_]+)/);
    const baseUrl = window.location.protocol + "//" + window.location.host;
    const transformedParts = parts.map((part) => {
      if (part.startsWith("/")) {
        return `<a href="${baseUrl + part}" target="_blank">${baseUrl + part}</a>`;
      }
      return part;
    });
    return transformedParts.join("");
  };

  const sanitizedAnswerV1 = data?.field_answer_v1 ? sanitize(transformMessage(data.field_answer_v1)) : '';
  const sanitizedAnswer = data?.field_answer ? sanitize(transformMessage(data.field_answer)) : '';

  let text;
  if (!!data?.field_datu_kopas_nosaukums) {
      text = data?.field_message_message_type + " <a target='_blank' href=" + data?.field_extra_text_link + ">" + data?.field_datu_kopas_nosaukums + "</a>" + ". ";
  } else {
      text = data?.field_message_message_type + " no administratora."
  }
  text += "<br> Ziņojums: " + transformMessage(data?.field_message_text);


  if (data?.field_template_body) {
    text = data?.field_template_body;
  }

  const sendAnswer = async () => {
    try {
      const result = await postCms(`api/v1/add/answer`, {
        recipientEmail: data?.field_sender_email,
        content: answerText,
        originalContent: data?.field_message_text,
        originalNid: data?.nid,
        answerStatus: answerStatus,
      });

      if (result?.error) {
        console.error(result?.error);
        return;
      }
      refetch();
      handleModalClose("iesutne");
    } catch (error) {
      console.error(error?.error ?? "An error occurred");
    }
  };


  const { handleModalClose } = useContext(StateContext);

  return [
    "Lietotāja jautājums par datu kopu",
    "Lietotāja kļūdas pieteikums par datu kopu",
    "Vispārējs lietotāja pieteikums par datu kopu",
    "Atbilde uz lietotāja jautājumu par datu kopu",
    "Atbilde uz lietotāja kļūdas pieteikumu par datu kopu",
    "Atbilde uz vispārēju lietotāja pieteikumu par datu kopu",
    "Lietotāja jautājums par portālu",
    "Lietotāja kļūdas pieteikums par portālu",
    "Vispārējs lietotāja pieteikums par portālu",
    "Atbilde uz lietotāja jautājumu par portālu",
    "Atbilde uz lietotāja kļūdas pieteikumu par portālu",
    "Atbilde uz vispārēju lietotāja pieteikumu par portālu",
  ].includes(data?.field_message_message_type) ? (
    <ModalComponent id="iesutne" trigger={trigger} show="eddModal">
      <div className="iesutne-dialog-content-de">
        <div className="iesutne-dialog-content-de-col">
          {data?.field_substatus && (
            <div
              className={`iesutne-dialog-content-de-label ${
                data?.field_substatus === "Noraidīts" ? "rejected" : ""
              }`}
            >
              {data?.field_substatus}
            </div>
          )}
          <div className="iesutne-dialog-content-de-description">
            <span>{data?.created || ""}</span>
            <span>•</span>
            <span>{data?.title_1 || ""}</span>
            <span>•</span>
            {renderSenderName(data?.field_message_message_type, data?.field_sender)}
          </div>
        </div>
        <div className="close-iesutne">
          <a
            href="#close"
            title="Close"
            className="close"
            onClick={() => {
              handleModalClose("iesutne");
            }}
          >
            <img alt="alt" className="close-icon" src="/assets/img/close1.svg" />
          </a>
        </div>
      </div>
      <div className="iesutne-dialog-content-header">
        {/* use old logic if field template title doesn't come from backend */}
        {console.log(data)}
        <p
          dangerouslySetInnerHTML={{
            __html: data?.field_template_title
              ? sanitize(data?.field_template_title)
              : data?.field_is_answered === "Jā"
              ? "Atbilde uz jūsu ziņojumu."
              : "Ir pienācis jauns lietotāja ziņojums.",
          }}
        ></p>
      </div>
      {data?.field_is_answered === "Jā" ? (
        <div className="iesutne-dialog-content-scrollbar">
          <p dangerouslySetInnerHTML={{ __html: sanitize(text) || "" }}></p>
          <br></br>
          <p dangerouslySetInnerHTML={{ __html: `Atbilde: ${sanitizedAnswerV1 || sanitizedAnswer}` }}></p>
        </div>
      ) : (
        <div className="iesutne-dialog-content-scrollbar">
          <p dangerouslySetInnerHTML={{ __html: sanitize(text) || "" }}></p>
        </div>
      )}
      {data?.field_is_answered === "Jā" ? (
        <></>
      ) : (
        <>
          <div style={{ width: "100%" }}>
            <textarea
              onChange={(e) => {
                setAnswerText(e.target.value);
              }}
              placeholder={t("enterYourMessage")}
              value={answerText}
              style={{ resize: "none", width: "55%" }}
              maxLength={250}
              rows={6}
            />
            <div>{answerText.length} / 250</div>
          </div>

          {data?.field_message_message_type === "Lietotāja kļūdas pieteikums par datu kopu" && (
            <div>
              <select
                defaultValue=""
                className="iesutne-dialog-content-dropdown"
                onChange={(e) => {
                  setAnswerStatus(e.target.value);
                }}
              >
                <option value="" disabled>
                  Izvēlieties statusu
                </option>
                <option value="resolved">{t("resolved")}</option>
                <option value="closed">{t("rejected")}</option>
              </select>
            </div>
          )}

          <PrimaryButton
            disabled={!answerStatus || !answerText}
            title={t("popupMessageSend")}
            style={{
              height: "4rem",
              width: "fit-content",
            }}
            onClick={() => {
              sendAnswer();
            }}
          />
        </>
      )}
    </ModalComponent>
  ) : (
    <ModalComponent id="iesutne" trigger={trigger}>
      <div className="iesutne-dialog-content-de">
        <div className="iesutne-dialog-content-de-col">
          {data?.field_status && <div className="iesutne-dialog-content-de-label">{data?.field_status}</div>}
          <div className="iesutne-dialog-content-de-description">
            <span>{data?.created || ""}</span>
            <span>•</span>
            <span>{data?.title_1 || ""}</span>
            <span>•</span>
            {renderSenderName(data?.field_message_message_type, data?.field_sender)}
          </div>
        </div>
        <div className="close-iesutne">
          <a
            href="#close"
            title="Close"
            className="close"
            onClick={() => {
              handleModalClose("iesutne");
            }}
          >
            <img alt="alt" className="close-icon" src="/assets/img/close1.svg" />
          </a>
        </div>
      </div>
      <div className="iesutne-dialog-content-header">{data?.title_1 || ""}</div>
      <div>
        <div
          className="iesutne-dialog-content-scrollbar"
          dangerouslySetInnerHTML={{
            __html: data?.field_message_text ? sanitize(transformMessage(data?.field_message_text)) : "",
          }}
        ></div>
      </div>
    </ModalComponent>
  );
};

export default IesutneModal;
